import React from 'react';
import TermsAndConditionsPageStyle from './style';
import MainImageComponent from 'src/components/mainImageComponent';
import useIsMobile from 'src/hooks/useIsMobile';
import useQueryApi from 'src/hooks/useApiQuery';
import { getTermAndCondition } from 'src/api/apiGetTermAndCondition';
import { Skeleton } from 'antd';
import { marked } from 'marked';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const TermsAndConditionsPage: React.FC = () => {
    const isMobile: boolean = useIsMobile();
    const { data, isLoading } = useQueryApi(['idItinerary'], () =>
        getTermAndCondition(),
    );

    const dataTerm =
        (data as any)?.fields?.body || '<p>No content available.</p>';

    const renderNote = () => (
        <>
            <div className="title">Terms & Conditions</div>
            {isLoading ? (
                <Skeleton active={true} style={{ width: '100%' }} />
            ) : (
                <div>
                    {parse(String(marked(dataTerm || '')))}
                    <a
                        href="https://www.yourgolftravel.com/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'rgb(26, 145, 255)' }}
                    >
                        See full terms and conditions{' '}
                        <FontAwesomeIcon
                            className="iconLink"
                            icon={faArrowUpRightFromSquare}
                        />
                    </a>
                </div>
            )}
        </>
    );

    return (
        <>
            {!isMobile && <MainImageComponent />}
            <TermsAndConditionsPageStyle
                style={{ minHeight: !isLoading ? '0' : '900px' }}
            >
                {renderNote()}
            </TermsAndConditionsPageStyle>
        </>
    );
};

export default TermsAndConditionsPage;
