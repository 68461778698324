import styled from '@emotion/styled';

const ModalConfirmSaveQuoteStyle = styled('div')`
    .contentModal {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: 400;
    }
    .quoteTitle {
        color: #069;
        font-size: 16px;
        font-weight: 400;
    }
    .btnEdit {
        margin-right: 16px;
    }
    .groupBtn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }
`;

export default ModalConfirmSaveQuoteStyle;
