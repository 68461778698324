import React, { useEffect, useState } from 'react';
import ModalMainImageStyle from './style';
import useQueryApi from 'src/hooks/useApiQuery';
import { getListImage } from 'src/api/apiGetListImage';
import { modifyURL } from 'src/const/contain';
import NotFound from 'src/components/notFoundData';
import { Button, Col, Popover, Row, Select, Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { getCountry } from 'src/api/apiGetCountry';
import { getTournamentnament } from 'src/api/apiGetTour';
import { useDispatch, useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import ModalComponent from '../modalCustom';
import { updateParamsQuoteDetail } from 'src/redux/actions/paramsQuoteDetailAction';

interface ModalMainImageProps {
    isVisible: boolean;
    onClose: () => void;
    isMulti?: boolean;
    valueImages?: string[];
}

const ModalChoseMainImage: React.FC<ModalMainImageProps> = ({
    isVisible,
    onClose,
    isMulti = false,
    valueImages = [],
}) => {
    const dispatch = useDispatch();
    const { isEvent, externalContent = [] } = useSelector(quoteDetailSelector);
    const [activeTab, setActiveTab] = useState<string>(isEvent ? '2' : '1');
    const [selectId, setSelectId] = useState<string>('');
    const [selectImages, setSelectImages] = useState<string[]>([]);

    const entryIds = externalContent
        ?.map((entryId: any) => `entryId=${entryId || 0}`)
        ?.join('&');

    const { data: itineraryImages = [], isLoading: isLoadingItinerary }: any =
        useQueryApi(['itineraryImages'], () => getListImage(entryIds), {
            enabled: externalContent.length > 0,
        });

    const {
        data: tourAndCountryImages = [],
        refetch,
        isFetching: isLoadingImage,
    }: any = useQueryApi(
        ['tourAndCountryImages'],
        () => {
            let param = isEvent
                ? `tournamentId=${selectId || 0}`
                : `countryId=${selectId || 0}`;

            return getListImage(param);
        },
        {
            enabled: !!selectId,
        },
    );

    const { data: selects = [] }: any = useQueryApi(['selects'], () =>
        isEvent ? getTournamentnament() : getCountry(),
    );

    const placeholder = [
        {
            value: '',
            label: `Select ${isEvent ? 'Tournament' : 'Country'}`,
        },
    ];
    const dataSelect = selects.map((item: any) => ({
        value: item.id,
        label: item.name,
    }));
    useEffect(() => {
        if (valueImages.length > 0) {
            setSelectImages(valueImages);
        }
    }, [valueImages]);

    const onChangeTab = (key: string) => {
        setActiveTab(key);
    };

    const onChangeSelect = async (id: string) => {
        setSelectId(id);
        setTimeout(() => refetch());
    };

    const handleChooseImage = (image: string) => {
        const isImageSelected = selectImages.some(
            (oldImage) => oldImage === image,
        );
        if (isImageSelected) {
            setSelectImages(
                selectImages.filter((oldImage) => oldImage !== image),
            );
        } else {
            if (isMulti) {
                setSelectImages((prev) => {
                    return [...prev, image];
                });
            } else {
                setSelectImages([image]);
            }
        }
    };

    const handleUpdateImage = () => {
        if (isMulti) {
            dispatch(updateParamsQuoteDetail({ images: selectImages }));
        } else {
            dispatch(
                updateParamsQuoteDetail({
                    mainImage: selectImages.length > 0 ? selectImages[0] : null,
                }),
            );
        }
        onClose();
    };

    const renderSelect = () => (
        <Select
            className="select"
            placeholder={`Select ${isEvent ? 'Tournament' : 'Country'}`}
            optionFilterProp="label"
            onChange={onChangeSelect}
            options={[...placeholder, ...dataSelect]}
            value={selectId}
        />
    );

    const renderWrapImage = (images: any) => (
        <>
            <div className="wrap-image">
                {(!isLoadingImage && activeTab === '2') ||
                (!isLoadingItinerary && activeTab === '1') ? (
                    <>
                        {images.length > 0 ? (
                            <Row gutter={[16, 16]} className="box-img">
                                {images.map((item: any, index: number) => (
                                    <Col key={index.toString()} xs={12} md={8}>
                                        <div
                                            className={
                                                selectImages?.includes(
                                                    item?.url,
                                                )
                                                    ? 'img-item selected'
                                                    : 'img-item'
                                            }
                                            onClick={() =>
                                                handleChooseImage(item?.url)
                                            }
                                        >
                                            <img
                                                alt={item?.alt}
                                                src={modifyURL(item?.url, 500)}
                                            />
                                            <Popover content={item?.alt} placement="bottom">
                                                <div className="name">
                                                    {item?.alt}
                                                </div>
                                            </Popover>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <NotFound content={<div>No data to display</div>} />
                        )}
                    </>
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Spin size="large" />
                    </div>
                )}
            </div>
            <div className="groupBtn">
                <Button
                    type="primary"
                    className="btnEdit"
                    onClick={onClose}
                    ghost
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    className="btnSend"
                    onClick={handleUpdateImage}
                >
                    Choose
                </Button>
            </div>
        </>
    );

    const items: TabsProps['items'] = [
        ...(isEvent
            ? []
            : [
                  {
                      key: '1',
                      label: 'Itinerary Image',
                      children: renderWrapImage(itineraryImages),
                  },
              ]),
        {
            key: '2',
            label: `${isEvent ? 'Tournament' : 'Country'} Image`,
            children: renderWrapImage(tourAndCountryImages),
        },
    ];
    const renderContent = () => (
        <ModalMainImageStyle>
            <Tabs
                defaultActiveKey={activeTab}
                items={items}
                type="card"
                onChange={onChangeTab}
                tabBarExtraContent={
                    isEvent || activeTab === '2' ? renderSelect() : ''
                }
            />
        </ModalMainImageStyle>
    );

    return (
        <ModalComponent
            width={1000}
            visible={isVisible}
            onClose={onClose}
            title="Choose main image"
            content={renderContent()}
        />
    );
};

export default ModalChoseMainImage;
