import { AnyAction } from 'redux';
import * as ActionType from '../actions/versionQuoteAction';

export interface VersionQuoteState {
    versionQuote: any;
}
const initialState: VersionQuoteState = {
    versionQuote: '',
};
export const versionQuoteReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.VERSION_QUOTE_STATE_SUCCESS:
            return {
                ...state,
                versionQuote: action.payload,
            };
        default:
            return state;
    }
};
