import styled from '@emotion/styled';

const TourOrEventCardStyle = styled('div')`
    &.card {
        position: relative;
        background-color: rgb(238, 249, 255);
    }

    &.edit {
        border: 2px solid rgb(18, 184, 151);
    }

    .iconAddComment {
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: -25px;
        margin-top: -25px;
        cursor: pointer;
        transition: all 0.2s;
        z-index: 1;
        &:hover {
            transform: scale(1.1);
        }
    }
    .description {
        margin-left: 16px;

        .tour-comment {
            margin-top: 5px;
            list-style: disc;
            padding-left: 5px;
            li + li {
                padding-top:5px;
            }
        }
    }

    @media screen and (max-width: 768px) {
    }
`;

export default TourOrEventCardStyle;
