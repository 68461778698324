import styled from '@emotion/styled';
import '../../../assets/colors.scss';
const ItineraryPageStyle = styled('div')`
    line-height: 1.4;
    min-height: 700px;
    .btn-choose-image {
        color: rgb(18, 184, 151);
        border-color: rgb(18, 184, 151);
        margin-bottom: 16px;
    }

    .carousel-box {
        .slick-prev,
        .slick-next {
            opacity: 0.65;
            &::after {
                border-inline-width: 5px 0;
                border-block-width: 5px 0;
            }
            &:hover {
                opacity: 1;
            }
        }
    }

    .carousel-item {
        position: relative;
        vertical-align: middle;
        &::before {
            display: block;
            content: '';
            width: 100%;
            padding-top: 50%;
        }
        img {
            right: 0;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            top: 0;
            position: absolute;
            object-fit: cover;
            border-radius: 6px;
        }
    }

    .card {
        border-radius: 8px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(156, 171, 194, 0.4) 4px 4px 20px 0px;
        padding: 24px;
        margin: 24px 0;

        .title-card {
            color: rgb(0, 0, 0);
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 12px;
        }

        .itinerary-title-card {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
            .icon-top {
                color: rgb(18, 184, 151);
                margin-top: 4px;
            }
        }

        .why-book {
            font-size: 14px;
            .item {
                display: flex;
                align-items: flex-start;
                gap: 12px;
                padding: 6px 0;

                .icon-check {
                    color: rgb(18, 184, 151);
                    margin-top: 3px;
                }
            }
        }
    }

    .please-read {
        .sub-card {
            color: rgb(0, 0, 0);
            font-size: 20px;
            margin-bottom: 16px;
        }

        .please-box {
            margin-bottom: 24px;
            .name {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 10px;
            }
            ul {
                list-style-type: disc;
                padding-left: 24px;
                li {
                    margin-bottom: 16px;
                }
            }
            a {
                word-wrap: break-word;
            }
        }
    }

    .main-image::before {
        padding-top: 30% !important;
    }

    @media screen and (max-width: 767px) {
        .card {
            padding: 16px;
        }
    }
`;
export default ItineraryPageStyle;
