import styled from '@emotion/styled';

const TitleQuoteDetailStyle = styled('div')`
    font-size: 38px;
    font-weight: 900;
    line-height: 1.2;
    color: #000;

    .edit-title {
        padding: 10px;
        border: 2px solid rgb(18, 184, 151);
    }
    .input {
        height: 60px;
        padding: 10px;
        border: 2px solid rgb(18, 184, 151);
        font-size: inherit;
        font-weight: 900;
        border-radius: 0;
        &.ant-input-status-error{
            border-color: #ff4d4f
        }
    }
    .max-length {
        color: rgb(18, 184, 151);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding: 5px 10px;
        border: 1px solid;
        display: table;
        margin-left: auto;
        margin-top: -30px;
    }

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
`;

export default TitleQuoteDetailStyle;
