import React from 'react';
import WhyBookStyle from './style';
import { useSelector } from 'react-redux';
import { Col, Row, Skeleton } from 'antd';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';

const WhyBook: React.FC = () => {
    const contentfulData = useSelector(contentfulSelector);

    const items = [1, 2, 3, 4].map((id) => ({
        id,
        imageId: contentfulData[`whyBookWithImage${id}`]?.sys?.id,
        title: contentfulData[`whyBookWithYgtTitle${id}`],
        detail: contentfulData[`whyBookWithYourGolfTravel${id}`],
    }));

    return (
        <WhyBookStyle className="box-line">
            <div className="intro-title">Why book with Your Golf Travel?</div>
            <Row gutter={[16, 16]}>
                {items.map((item) => {
                    return (
                        <Col xs={24} md={6} key={item.id}>
                            <WhyBookItem
                                imageId={item.imageId}
                                title={item.title}
                                content={item.detail}
                            />
                        </Col>
                    );
                })}
            </Row>
        </WhyBookStyle>
    );
};

export default WhyBook;

interface WhyBookItemProps {
    imageId?: string | undefined;
    title?: string;
    content?: string;
}

const WhyBookItem: React.FC<WhyBookItemProps> = ({
    imageId,
    title,
    content,
}) => {
    const { data: imageFields }: any = useQueryApi(
        ['image', imageId],
        () => getImageApi(imageId),
        {
            enabled: !!imageId,
        },
    );

    return (
        <>
            {imageFields ? (
                <div className="box-why">
                    <div className="why-image">
                        {imageFields?.fields?.file?.url && (
                            <img
                                alt=""
                                className="iconWhy"
                                src={`https:${imageFields?.fields?.file?.url}`}
                            />
                        )}
                    </div>
                    <div>
                        <div className="why-title">{title}</div>
                        <div className="why-detail">{content}</div>
                    </div>
                </div>
            ) : (
                <Skeleton.Image active={true} className="allItem" />
            )}
        </>
    );
};
