import styled from '@emotion/styled';

const NextStepsStyle = styled('div')`
    font-size: 16px;
    line-height: 1.3;
    .box-step {
        .step-num {
            width: 40px;
            height: 40px;
            background: #12b897;
            color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 900;
            margin-bottom: 24px;
        }
        .step-title {
            font-weight: 700;
            margin-bottom: 3px;
        }
        .link {
            color: rgb(26, 145, 255);
            cursor: pointer;
            text-decoration-line: underline;
        }
    }
    @media screen and (max-width: 767px) {
        .box-step {
            display: flex;
            gap: 16px;
            .step-num {
                margin-bottom: 0px;
            }
        }
    }
`;
export default NextStepsStyle;
