import React from 'react';
import AvatarConsultantStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const AvatarConsultant: React.FC = () => {
    const { resConsultant } = useSelector(quoteDetailSelector);
    const { image } = resConsultant;

    return (
        <AvatarConsultantStyle>
            {image ? (
                <img alt="" className="avatarImage" src={image} />
            ) : (
                <FontAwesomeIcon className="avatar" icon={faCircleUser} />
            )}
        </AvatarConsultantStyle>
    );
};

export default AvatarConsultant;
