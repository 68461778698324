import styled from '@emotion/styled';

const FooterQuoteDetailStyle = styled('div')`
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #152643;
    padding: 24px 0;
    font-weight: 900;
    color: #fff;
    line-height: 1.4;
    z-index: 99;

    .box-footer {
        display: flex;
        align-items: center;
        gap: 32px;
    }

    .btnLink {
        background-color: rgb(18, 184, 151) !important;
        height: 56px;
        font-weight: 900;
        font-size: 20px;
    }

    .link {
        color: #93CBFF;
        cursor: pointer;
    }

    @media screen and (min-width: 992px) {
        font-size: 24px;
        .btnLink {
            min-width: 400px;
        }
    }
    @media screen and (max-width: 767px) {
        box-shadow: -14px 0px 13px 0px rgba(0, 0, 0, 0.08);
        border-radius: 16px 16px 0 0;

        .box-footer {
            flex-direction: column;
            justify-content: center;
            gap: 10px;
        }

        .btnLink {
            width: 100%;
        }

        .hide-mobile {
            display: none;
        }
    }
`;
export default FooterQuoteDetailStyle;
