import React, { useState } from 'react';
import NavbarQuoteDetailStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import ModalComponent from 'src/components/modalCustom';
import { routerQuoteDetailData } from 'src/const/enum';
import { Link, useLocation } from 'react-router-dom';
import CopyLinkVersion from 'src/components/copyLinkVersion';
import icons from 'src/assets/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { ENV } from 'src/const/evn';
import { Button } from 'antd';
import YourMasterComponent from 'src/components/yourMaster';
import { itinerarySelector } from 'src/redux/selectors/itinerarySelector';
import CartWrap from 'src/components/cartWrap';
import AvatarConsultant from 'src/components/avatarConsultant';
import ItineraryScrollComponent from 'src/components/itineraryLinkCroll';
import { formatCurrency } from 'src/const/contain';

interface NavbarQuoteDetailProps {
    isPreview: boolean;
}

const NavbarQuoteDetail: React.FC<NavbarQuoteDetailProps> = ({isPreview}) => {
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const quoteIdQuery = queryParams.get('id') || '';

    const {
        id: quoteId = '',
        resConsultant,
        price,
        isEvent,
        version,
    } = useSelector(quoteDetailSelector);

    const { totalDeposit } = price;
    const { id: versionId } = version;

    const { atAGlance } = useSelector(itinerarySelector);
    const {
        name: nameConsultant,
        jobTitle,
        companyName,
    } = resConsultant;
    const lastName = nameConsultant?.split(' ').slice(0, -1).join(' ');

    const handleShowModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const pathName = (path: string) => {
        return `/quoteDetail/${path}?id=${quoteIdQuery}`;
    };

    const isCheckPathName = (path: string) => {
        return location.pathname === `/quoteDetail/${path}`;
    };

    const handleRedirectToPay = () => {
        window.open(`${ENV.URL_PAYMENT}/quotes/QV${quoteId}`, '_blank');
    };

    const renderConsultant = () => (
        <div className="card">
            <div className="box-consultant">
                <div className="consultant">
                    <p>“I’m {lastName} and I’ll be looking after your trip.”</p>
                    <p className="golfer">{nameConsultant}</p>
                    {jobTitle && <p>{jobTitle}</p>}
                    {companyName && (
                        <p>
                            {companyName
                                ?.replace('Golf ', '')
                                ?.replace('plus', '+')}
                        </p>
                    )}
                </div>
                <AvatarConsultant />
            </div>
            <Button
                type="primary"
                className="btn-pay"
                onClick={handleRedirectToPay}
            >
                Secure Quote Now for £{formatCurrency(totalDeposit)}
            </Button>
        </div>
    );

    const renderBoxNav = () => (
        <div className="card">
            <ul className="navbar">
                {routerQuoteDetailData.map((item) => (
                    <li key={item.path}>
                        <Link
                            to={pathName(item.path)}
                            className={
                                isCheckPathName(item.path) ? 'active' : ''
                            }
                        >
                            {isCheckPathName(item.path) && (
                                <img
                                    alt=""
                                    className="arrowLeft"
                                    src={icons.arrowLeft}
                                />
                            )}
                            {item.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );

    const renderMiniCart = () => (
        <div className="card mini-basket">
            <div className='reference'>Quote reference: {quoteId}</div>
            <CartWrap />
        </div>
    );

    const renderShareQuote = () => (
        <>
            <Button
                type="primary"
                className="btnShare"
                ghost
                onClick={handleShowModal}
            >
                Share Quote <FontAwesomeIcon icon={faShareNodes} />
            </Button>
            <ModalComponent
                width={500}
                visible={isModalVisible}
                onClose={handleCloseModal}
                title={'Share your quote with your fellow golfers'}
                content={<CopyLinkVersion onDoneCopy={handleCloseModal} />}
            />
        </>
    );

    return (
        <NavbarQuoteDetailStyle>
            {renderConsultant()}
            {renderBoxNav()}
            {renderMiniCart()}
            {isEvent && <YourMasterComponent atAGlance={atAGlance} />}
            {isEvent && isCheckPathName("itineraryInDetail") && <ItineraryScrollComponent />}
            {versionId && renderShareQuote()}
        </NavbarQuoteDetailStyle>
    );
};

export default NavbarQuoteDetail;
