import styled from '@emotion/styled';

const WhyBookStyle = styled('div')`
    font-size: 16px;
    line-height: 1.3;
    .box-why {
        .why-image {
            height: 50px;
            min-width:70px;
            margin-bottom: 16px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .why-title {
            font-weight: 700;
            margin-bottom: 3px;
        }
        .link {
            color: rgb(26, 145, 255);
            cursor: pointer;
            text-decoration-line: underline;
        }
    }
    @media screen and (max-width: 767px) {
        .box-why {
            display: flex;
            gap: 16px;
            .why-num {
                margin-bottom: 0px;
            }
        }
    }
`;
export default WhyBookStyle;
