import React, { useCallback, useEffect, useState } from 'react';
import CommentComponentStyle from './style';
import { Popover } from 'antd';
import {
    setParamsQuoteDetail,
    updateParamsQuoteDetail,
} from 'src/redux/actions/paramsQuoteDetailAction';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import AvatarConsultant from '../avatarConsultant';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';

const maxLength = 250;

interface CommentComponentProps {
    isEdit: boolean;
    initialValue: string;
    onChangeToggleInput: () => void;
    data?: any;
}

const CommentComponent: React.FC<CommentComponentProps> = ({
    isEdit,
    initialValue,
    onChangeToggleInput,
    data,
}) => {
    const dispatch = useDispatch();
    const [isShowInput, setIsShowInput] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const paramsQuote = useSelector(paramsQuoteDetailSelector);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const itineraryComments: any[] = paramsQuote?.itineraryComments || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const newComment = {
        itemType: data?.type,
        itemId: data?.id,
        itineraryDate: data?.itineraryDate,
        comment: initialValue,
    };

    useEffect(() => {
        setIsShowInput(isEdit);
    }, [isEdit]);

    useEffect(() => {
        setInputValue(initialValue);
    }, [itineraryComments, initialValue]);

    const updateOrPushOrRemoveItineraryComment = (
        comments: any[],
        newComment: any,
        shouldRemoveIfExists: boolean = false,
    ) => {
        const existingIndex = comments.findIndex(
            (comment) =>
                comment?.itemId === newComment?.itemId &&
                comment?.itineraryDate === newComment?.itineraryDate,
        );
        if (existingIndex !== -1) {
            if (shouldRemoveIfExists) {
                comments.splice(existingIndex, 1);
            } else comments[existingIndex].comment = newComment.comment;
        } else {
            comments.push(newComment);
        }
        return comments;
    };

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            let value = e.target.value;
            setInputValue(value);
            if (data?.type) {
                newComment.comment = value;
                dispatch(
                    updateParamsQuoteDetail({
                        itineraryComments: updateOrPushOrRemoveItineraryComment(
                            itineraryComments,
                            newComment,
                        ),
                    }),
                );
            } else {
                dispatch(updateParamsQuoteDetail({ priceAgentComment: value }));
            }
        },
        [data?.type, dispatch, itineraryComments, newComment],
    );

    const handleCancel = () => {
        if (data?.type) {
            const newItineraryComments = updateOrPushOrRemoveItineraryComment(
                itineraryComments,
                newComment,
                true,
            );
            if (newItineraryComments.length > 0) {
                dispatch(
                    updateParamsQuoteDetail({
                        itineraryComments: newItineraryComments,
                    }),
                );
            } else {
                delete paramsQuote?.itineraryComments;
                dispatch(setParamsQuoteDetail(paramsQuote));
            }
        } else {
            if (paramsQuote?.priceAgentComment) {
                delete paramsQuote.priceAgentComment;
                dispatch(setParamsQuoteDetail(paramsQuote));
            }
        }
        onChangeToggleInput();
    };

    const renderComment = () => (
        <>
            {initialValue && (
                <CommentComponentStyle>
                    <div className="box-comment">
                        <div className="comment-value">{initialValue}</div>
                        <div className="box-avatar">
                            <AvatarConsultant />
                        </div>
                    </div>
                </CommentComponentStyle>
            )}
        </>
    );

    const renderPopover = () => (
        <Popover content={'Click to edit'}>
            <div onClick={onChangeToggleInput}>{renderComment()}</div>
        </Popover>
    );

    const renderInputTitle = () => (
        <CommentComponentStyle>
            <div className="box-comment edit">
                <div className="comment-value">
                    <div className="max-length">
                        {inputValue?.length || 0}/{maxLength}
                    </div>
                    <TextArea
                        className="textArea"
                        variant="borderless"
                        defaultValue={initialValue}
                        onChange={handleInputChange}
                        maxLength={maxLength}
                        placeholder="Type your message here..."
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    <div className="btn-cancel" onClick={handleCancel}>
                        Cancel
                    </div>
                </div>
                <div className="box-avatar">
                    <AvatarConsultant />
                </div>
            </div>
        </CommentComponentStyle>
    );

    return (
        <>
            {isEditPage
                ? isShowInput
                    ? renderInputTitle()
                    : renderPopover()
                : renderComment()}
        </>
    );
};

export default CommentComponent;
