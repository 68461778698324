import React from 'react';
import ImportantPageStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import MainImageComponent from 'src/components/mainImageComponent';
import useIsMobile from 'src/hooks/useIsMobile';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';

const ImportantPage: React.FC = () => {
    const isMobile: boolean = useIsMobile();
    const { isEvent } = useSelector(quoteDetailSelector);

    const { quoteImportantNotesTour = {}, quoteImportantNotesStandard = {} } =
        useSelector(contentfulSelector);

    const extractTextValues = (data: any) => {
        const textValues: string[] = [];

        function traverse(node: any): void {
            if (node.nodeType === 'text') {
                textValues.push(node.value);
            } else if (node.content && Array.isArray(node.content)) {
                node.content.forEach((child: any) => traverse(child as any));
            }
        }

        traverse(data);
        return textValues;
    };

    const notes: any =
        (isEvent
            ? extractTextValues(quoteImportantNotesTour)
            : extractTextValues(quoteImportantNotesStandard)) || [];

    const renderNote = () => (
        <>
            <div className="title">Important Notes</div>
            <ul>
                {notes
                    .filter((item: any) => item !== '')
                    .map((item: string, index: number) => {
                        return (
                            <li key={index} className="content">
                                {item}
                            </li>
                        );
                    })}
            </ul>
        </>
    );

    return (
        <>
            {!isMobile && <MainImageComponent />}
            <ImportantPageStyle>{renderNote()}</ImportantPageStyle>
        </>
    );
};

export default ImportantPage;
