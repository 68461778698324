import styled from '@emotion/styled';
const ReadyToBookStyle = styled('div')`
    padding: 54px 64px;
    background: rgb(240, 255, 254);
    margin-bottom: 40px;
    margin-top: 20px;
    font-size: 16px;

    .title-ready {
        color: rgb(0, 0, 0);
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 14px;
    }

    .content-ready {
        line-height: 1.3;
        margin-bottom: 32px;
    }

    .btn-pay {
        background-color: rgb(18, 184, 151) !important;
        width: 100%;
        height: 56px;
        font-weight: 900;
        font-size: 18px;
    }

    @media screen and (max-width: 767px) {
        padding: 16px 0;
        background: none;
    }
`;
export default ReadyToBookStyle;
