import React from 'react';
import IntroductionPageStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import ViewAndEditQuoteIntro from 'src/components/viewAndEditQuoteIntro';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';

import AvatarConsultant from 'src/components/avatarConsultant';
import { Flex } from 'antd';
import useQueryApi from 'src/hooks/useApiQuery';
import { getDetailExpert } from 'src/api/apiGetDetailExpert';
import WhyBook from './whyBook';
import NextSteps from './nextSteps';
import Reviews from './reviews';
import MainImageComponent from 'src/components/mainImageComponent';
import useIsMobile from 'src/hooks/useIsMobile';

const IntroductionPage: React.FC = () => {
    const {
        intro = '',
        isEvent,
        clientComments,
        resConsultant,
    } = useSelector(quoteDetailSelector);

    const { agentIntroStandard, salesAgentIntroTournament } =
        useSelector(contentfulSelector);

    const { introParagraph = [], promotionalMessage = [] } = clientComments;

    const {
        name: nameConsultant,
        email,
        telephone,
        companyName,
    } = resConsultant;
    const lastName = nameConsultant?.split(' ').slice(0, -1).join(' ');

    const isMobile: boolean = useIsMobile();

    const { data: detailExpert }: any = useQueryApi(
        ['nameResConsultant', nameConsultant],
        () => getDetailExpert(nameConsultant),
        {
            enabled: !!nameConsultant,
        },
    );

    const extractTextIntro = (data: any) => {
        if (data && data?.content && data?.content?.length > 0) {
            const modifiedData: any = {
                ...data,
                content: [...data.content],
            };
            modifiedData.content.pop();

            let text: string = '';
            modifiedData.content.forEach((item: any) => {
                if (item.content) {
                    item.content.forEach((innerItem: any) => {
                        if (innerItem.value) {
                            text += innerItem.value + '<br><br>';
                        }
                    });
                }
            });
            return text.trim();
        }
        return '';
    };

    const getLastText = (content: any) => {
        if (content && content.length > 0) {
            const lastParagraph = content[content.length - 1];

            if (
                lastParagraph &&
                lastParagraph.nodeType === 'paragraph' &&
                lastParagraph.content
            ) {
                let text: string = '';
                lastParagraph.content.forEach((innerItem: any) => {
                    if (innerItem.nodeType === 'text' && innerItem.value) {
                        text += innerItem.value + ' ';
                    }
                });
                return text.trim();
            }
        }
        return '';
    };

    const extractDetailExpert = (data: any[]) => {
        return data
            ?.map((item: any) =>
                item.content.map((content: any) => content.value).join('<br>'),
            )
            .join('<br>');
    };

    const introQuote = intro
        ? intro
        : isEvent
        ? extractTextIntro(salesAgentIntroTournament)
        : extractTextIntro(agentIntroStandard);

    const signatureName = isEvent
        ? getLastText(salesAgentIntroTournament?.content || [])
        : getLastText(agentIntroStandard?.content || []);

    const renderBoxIntro = () => (
        <div className="box-intro">
            <ViewAndEditQuoteIntro initialValue={introQuote} />
            {introParagraph.length > 0 && (
                <div
                    className="m-bottom max-w"
                    dangerouslySetInnerHTML={{
                        __html: introParagraph
                            .join('<br /><br />')
                            .replace(/\n/g, '<br />'),
                    }}
                ></div>
            )}
            {promotionalMessage.length > 0 && (
                <div className="m-bottom max-w">
                    <b
                        dangerouslySetInnerHTML={{
                            __html: promotionalMessage
                                .join('')
                                .replace(/\n/g, '<br />'),
                        }}
                    ></b>
                </div>
            )}
            <div>{signatureName}</div>
            <div className="info-seller">
                <p className="text-signature">{lastName}</p>
                <div>
                    <p>{nameConsultant}</p>
                    {telephone && <p>{telephone}</p>}
                    {email && (
                        <p className="color-success text-line">{email}</p>
                    )}
                </div>
            </div>
        </div>
    );

    const renderBoxAbout = () => (
        <div className="box-about">
            <div className="intro-title">
                About <span className="color-success">{nameConsultant} </span> -
                Your Golf Travel Expert
            </div>
            <div
                className="detail"
                dangerouslySetInnerHTML={{
                    __html: extractDetailExpert(
                        detailExpert?.fields?.documentation?.content || [],
                    ),
                }}
            ></div>
            <Flex justify="end">
                <Flex gap={24}>
                    <AvatarConsultant />
                    <div className="consultant">
                        <p className="text-signature">{nameConsultant}</p>
                        {telephone && <p>{telephone}</p>}
                        {email && (
                            <p className="color-success text-line">{email}</p>
                        )}
                        {companyName && (
                            <p>
                                {companyName
                                    ?.replace('Golf ', '')
                                    ?.replace('plus', '+')}
                            </p>
                        )}
                    </div>
                </Flex>
            </Flex>
        </div>
    );

    return (
        <IntroductionPageStyle>
            {!isMobile && <MainImageComponent/>}
            {renderBoxIntro()}
            <WhyBook />
            <Reviews />
            <NextSteps />
            {renderBoxAbout()}
        </IntroductionPageStyle>
    );
};

export default IntroductionPage;
