import styled from '@emotion/styled';

const FlightCardStyle = styled('div')`
    position: relative;

    &.edit {
        border: 2px solid rgb(18, 184, 151);
    }

    .iconAddComment {
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: -25px;
        margin-top: -25px;
        cursor: pointer;
        transition: all 0.2s;
        z-index: 1;
        &:hover {
            transform: scale(1.1);
        }
    }
    .item-flight {
        margin-bottom: 10px;
        .icon-detail {
            color: rgb(18, 184, 151);
            margin-top: 3px;
        }
    }
    .flight-info {
        color: rgb(0, 0, 0);
        text-align: right;
        font-size: 14px;
        font-weight: 500;
    }
`;

export default FlightCardStyle;
