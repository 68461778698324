import styled from '@emotion/styled';

const AdditionalInfoPageStyle = styled('div')`
    line-height: 1.4;
    padding: 24px;
    position: relative;
    background: rgb(29, 50, 84);
    color: #fff;
    margin: 24px 0;
    border-radius: 4px;

    &.edit {
        // border: 2px solid rgb(18, 184, 151);
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .quill {
        background: #fff;
        color: initial;

        .ql-editor {
            overflow-y: auto;
            height: 200px;
        }
    }

    .btn-cancel {
        display: table;
        margin-left: auto;
        margin-top: 10px;
        cursor: pointer;
    }

    .iconAddComment {
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: -25px;
        margin-top: -25px;
        cursor: pointer;
        z-index: 1;
        &:hover {
            transform: scale(1.1);
        }
    }

    @media screen and (max-width: 767px) {
    }
`;
export default AdditionalInfoPageStyle;
