import styled from '@emotion/styled';
const AvatarConsultantStyle = styled('div')`
    width: 89px;
    height: 89px;
    overflow: hidden;
    border: 2px solid #12b897;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .avatar {
        color: #12b897;
        object-fit: cover;
        font-size: 70px;
    }
`;
export default AvatarConsultantStyle;
