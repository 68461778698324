import styled from '@emotion/styled';
import '../../../assets/colors.scss';
const HeaderQuoteDetailStyle = styled('div')`
    &.preview {
        padding-top: 64px;
    }

    .box-preview {
        background-color: rgb(255, 246, 223);
        position: fixed;
        width: 100%;
        z-index: 99;
        padding: 16px 0;
        font-size: 16px;
        top: 0;
        left: 0;

        .role {
            line-height: 20px;
            padding: 6px 0;
        }
    }

    .box-header {
        padding-top: 20px;
        padding-bottom: 20px;

        .logo {
            max-width: 240px;
            img {
                max-width: 100%;
            }
        }

        .promotion {
            display: flex;
            align-items: center;
            border: 2px solid;
            color: #1a91ff;
            border-radius: 6px;
            font-weight: 700;
            font-size: 21px;
            justify-content: center;
            min-height: 64px;
            padding: 8px 1rem;
            margin-top: 1rem;

            .iconCoins {
                margin-right: 1.5rem;
                font-size: 28px;
            }
        }
    }

    .title-detail {
        padding: 36px 0;
        background-color: #f2f2f2;

        .sub-title {
            font-size: 38px;
            font-weight: 500;
            color: rgb(102, 102, 102);
            margin-top: 10px;
        }
    }

    @media screen and (max-width: 767px) {
        .box-preview {
            .role {
                display: none;
            }
        }
        .box-header {
            .promotion {
                flex-direction: row-reverse;
                font-size: 14px;
                min-height: 40px;

                .iconCoins {
                    margin-left: 1.5rem;
                    margin-right: 0;
                    font-size: 18px;
                }
            }
        }

        .title-detail {
            padding: 18px 0;

            .sub-title {
                font-size: 20px;
            }
        }
    }
`;
export default HeaderQuoteDetailStyle;
