import React, { useEffect, useState, Fragment } from 'react';
import TourOrEventCardStyle from './style';
import icons from 'src/assets/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CommentComponent from '../comment';
import { Flex } from 'antd';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';

interface TourOrEventCardProps {
    data: any;
}

const TourOrEventCardComponent: React.FC<TourOrEventCardProps> = ({ data }) => {
    const {
        agentComments = [],
        description,
        quantity,
        tourDayComments = [],
    } = data;

    const agentComment = agentComments.length > 0 ? agentComments[0] : {};
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const { isEvent } = useSelector(quoteDetailSelector);
    const [isAddComment, setIsAddComment] = useState(false);

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const { itineraryComments = [] }: any = useSelector(
        paramsQuoteDetailSelector,
    );

    const updateComment = itineraryComments.find(
        (comment: any) =>
            comment?.itemId === data?.id &&
            comment?.itineraryDate === data?.itineraryDate,
    )?.comment;

    const renderContent = () => (
        <>
            <TourOrEventCardStyle className={isEditPage ? 'card edit' : 'card'}>
                <Flex
                    justify="space-between"
                    gap={16}
                    className="itinerary-title-card"
                >
                    <div>{`${description} ${quantity ? `x${quantity}`:""}`}</div>
                    {isEvent && (
                        <FontAwesomeIcon icon={faTrophy} className="icon-top" />
                    )}
                </Flex>
                <div className="description">
                    {tourDayComments && tourDayComments.length > 0 && (
                        <ul className="tour-comment">
                            {tourDayComments?.map((item: any, index: any) => {
                                const formattedText = item?.text
                                    ?.replace(/\r\n/g, '<br />')
                                    ?.replace(/\n/g, '<br />');
                                return (
                                    <li
                                        className="item"
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: formattedText,
                                        }}
                                    />
                                );
                            })}
                        </ul>
                    )}
                </div>
                {isEditPage && renderComment()}
            </TourOrEventCardStyle>
            <CommentComponent
                isEdit={isAddComment}
                initialValue={updateComment ?? agentComment?.comment ?? ''}
                onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                data={data}
            />
        </>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return renderContent();
};

export default TourOrEventCardComponent;
