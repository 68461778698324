import { PayloadAction } from '@reduxjs/toolkit';
import * as ActionType from '../actions/quoteDetailAction';

export interface QuoteDetailState {
    quoteDetail: any;
}
const initialState: QuoteDetailState = {
    quoteDetail: {},
};
export const quoteDetailReducer = (
    state = initialState,
    action: PayloadAction<any>,
) => {
    switch (action.type) {
        case ActionType.SET_QUOTE_DETAIL:
            return {
                quoteDetail: action.payload,
            };
        case ActionType.UPDATE_QUOTE_DETAIL:
            return {
                ...state,
                quoteDetail: { ...state.quoteDetail, ...action.payload },
            };
        default:
            return state;
    }
};
