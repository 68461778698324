import { AnyAction } from 'redux';
import * as ActionType from '../actions/updatePriceAction';

export interface pricePersonState {
    price: any[];
}
const initialState: pricePersonState = {
    price: [],
};
export const editPricePersonReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.UPDATE_EDIT_PRICE_SUCCESS:
            return {
                ...state,
                price: [...state.price,action.payload],
            };
        default:
            return state;
    }
};