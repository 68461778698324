import React, { useCallback, useEffect, useState } from 'react';
import QuoteDetailPageStyle from './style';
import { useLocation, Outlet } from 'react-router-dom';
import useQueryApi from 'src/hooks/useApiQuery';
import { getQuoteDetailVersion } from 'src/api/apiGetQuoteDetailVersion';
import { getDetailQuoteContentful } from 'src/api/apiGetDetailQuoteContentful';
import { setContentful } from 'src/redux/actions/contentfulAction';
import { setQuoteDetail } from 'src/redux/actions/quoteDetailAction';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Row, Col } from 'antd';
import HeaderQuoteDetail from './header';
import NavbarQuoteDetail from './navbar';
import { setItinerary } from 'src/redux/actions/itineraryAction';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import FooterQuoteDetail from './footer';
import { getQuoteDetail } from 'src/api/apiGetQuoteDetail';

const QuoteDetailPage = () => {
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const quoteId = queryParams.get('id') || '';
    const isPreview = !!Number(quoteId);

    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    const { data: dataVersion, isLoading: isLoadingVersion } = useQueryApi(
        ['id', quoteId],
        () => getQuoteDetailVersion(quoteId),
        {
            enabled: !!quoteId && !isPreview,
        },
    );

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [responseContentful, response]: [
                any,
                any,
            ] = await Promise.all([
                getDetailQuoteContentful(),
                quoteId ? getQuoteDetail(quoteId) : Promise.resolve(null),
            ]);
            setDataQuote(
                responseContentful?.fields,
                response?.introduction,
                response?.quoteItinerary,
            );
        } catch (err: any) {
            toast.error(err?.message || '');
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteId]);

    useEffect(() => {
        if (isPreview) {
            fetchData();
        } else {
            const data = (dataVersion as { data: any })?.data;
            setDataQuote(
                data?.contentful,
                data?.introduction,
                data?.quoteItinerary,
            );
            if (!isLoadingVersion) {
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVersion, fetchData, isPreview, isLoadingVersion]);

    const combinedLoading = !isLoadingVersion && !isLoading;

    const setDataQuote = (
        contentfulData: any,
        quoteDetailData: any,
        itinerary: any,
    ) => {
        if (contentfulData) {
            dispatch(setContentful(contentfulData));
        }
        if (quoteDetailData) {
            dispatch(setQuoteDetail(quoteDetailData));
        }
        if (itinerary) {
            dispatch(setItinerary(itinerary));
        }
    };

    const quoteDetail = useSelector(quoteDetailSelector);
    const contentfulData = useSelector(contentfulSelector);

    if (!combinedLoading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Spin size="large" />
            </div>
        );
    }
    return (
        <>
            {!isEmpty(quoteDetail) && !isEmpty(contentfulData) && (
                <QuoteDetailPageStyle>
                    <HeaderQuoteDetail isPreview={isPreview} />
                    <div className="container-ydp detail-content">
                        <Row gutter={[16, 16]}>
                            <Col span={24} lg={{order: 2, span: 7}}>
                                <NavbarQuoteDetail isPreview={isPreview} />
                            </Col>
                            <Col span={24} lg={{order: 1, span: 17}} id="quote-detail">
                                <Outlet />
                            </Col>
                        </Row>
                    </div>
                    <FooterQuoteDetail isPreview={isPreview} />
                </QuoteDetailPageStyle>
            )}
        </>
    );
};

export default QuoteDetailPage;
