import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Input, Spin } from 'antd';
import ModalConfirmVersionStyle from './style';
import { useSelector, useDispatch } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import { updateQuoteDetail } from 'src/redux/actions/quoteDetailAction';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { saveVersionApi } from 'src/api/apiSaveVersion';
import CopyLinkVersion from '../copyLinkVersion';
import ModalComponent from '../modalCustom';

interface ModalConfirmVersionProps {
    onClose: () => void;
    typeModal: string;
    isVersionChange: boolean;
}

const ModalConfirmVersion: React.FC<ModalConfirmVersionProps> = ({
    onClose,
    typeModal,
    isVersionChange,
}) => {
    const dispatch = useDispatch();
    const isTypeGenerate = typeModal === 'GenLink';
    const { version, title, id } = useSelector(quoteDetailSelector);
    const contentfulData = useSelector(contentfulSelector);
    const { version: versionName } = version;
    const versionNum = versionName?.substring(1);

    const [isLoading, setIsLoading] = useState(false);
    const [isViewCopyLink, setIsViewCopyLink] = useState(false);
    const [versionDetail, setVersionDetail] = useState<string>('');
    

    const handleChangeVersionDetail = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setVersionDetail(e.target.value);
    };

    const handleCloseCopyLink = ()=> {
        setIsViewCopyLink(false)
    }

    const { mutate } = useMutationApi<{ param: any }, any>(
        ({ param }) => saveVersionApi(param),
        {
            onSuccess: (response) => {
                toast.success(
                    isTypeGenerate
                        ? 'Quote link is generated successfully'
                        : 'Email sent successfully!',
                );
                if (isVersionChange && response) {
                    const paramsVersion = {
                        version: {
                            id: response?.versionId,
                            version: response?.versionName,
                            status: "Sent"
                        }
                    }
                    dispatch(updateQuoteDetail(paramsVersion))
                }
                setIsLoading(false);
                setVersionDetail("")
                onClose();
                if (isTypeGenerate) {
                    setIsViewCopyLink(true);
                }
            },
            onError: (error) => {
                if (error?.message === 'Client email not found') {
                    toast.error(
                        `No client email found, please add an email address to send`,
                    );
                } else if (error?.message === 'Client not found') {
                    toast.error(
                        `No client type found on Reservations, please make sure you have linked client first before sending quote`,
                    );
                } else {
                    toast.error(error?.message);
                }
                setIsLoading(false);
                onClose();
            },
        },
    );

    const handleSaveVersion = () => {
        const data = {
            quoteRef: id,
            detail: versionDetail,
            contentful: contentfulData,
            skipEmail: isTypeGenerate,
        };
        mutate({ param: data });
        setIsLoading(true);
    };

    const renderGroupBtn = () => (
        <div className="groupBtn">
            <Button
                type="primary"
                className="btnEdit"
                onClick={() => {
                    onClose();
                }}
                ghost
            >
                {'Cancel'}
            </Button>
            <Spin spinning={isLoading}>
                <Button
                    type="primary"
                    className="btnSend"
                    onClick={handleSaveVersion}
                >
                    {isTypeGenerate ? 'Generate' : 'Send'}
                </Button>
            </Spin>
        </div>
    );

    const renderBoxUpdateVersion = () => (
        <>
            <div className="contentModal">
                Are you sure you want to{' '}
                {isTypeGenerate
                    ? 'generate new version for quote:'
                    : 'send the quote'}{' '}
                <span className="quoteTitle">{title}</span> - Version
                <span className="quoteTitle">
                    {' '}
                    {Number(versionNum) ? Number(versionNum) + 1 : 1}?
                </span>
            </div>
            <div className="groupInput">
                <div>Version details</div>
                <Input
                    className="inputNew"
                    value={versionDetail}
                    placeholder="Enter your content here"
                    onChange={handleChangeVersionDetail}
                />
            </div>
            {renderGroupBtn()}
        </>
    );

    const renderWarnSave = () => (
        <>
            <div className="text-require">
                There are no changes on this quote, do you want to send out the
                previous quote version instead?
            </div>
            {renderGroupBtn()}
        </>
    );

    return (
        <ModalConfirmVersionStyle>
            {isVersionChange ? (
                renderBoxUpdateVersion()
            ) : isTypeGenerate ? (
                <CopyLinkVersion onDoneCopy={onClose} />
            ) : (
                renderWarnSave()
            )}
            
            <ModalComponent
                width={500}
                visible={isViewCopyLink}
                onClose={handleCloseCopyLink}
                title="Share your quote with your fellow golfers"
                content={
                    <CopyLinkVersion onDoneCopy={handleCloseCopyLink} />
                }
            />
        </ModalConfirmVersionStyle>
    );
};

export default ModalConfirmVersion;
