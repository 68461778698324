import React from 'react';
import LinkScrollItineraryStyle from './style';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { itinerarySelector } from 'src/redux/selectors/itinerarySelector';

const ItineraryScrollComponent = () => {
    const { itinerary = [] } = useSelector(itinerarySelector);

    const handleScroll = (id: any) => {
        const event = new CustomEvent('scrollToSectionId', { detail: { id } });
        window.dispatchEvent(event);
    };

    return (
        <>
            {itinerary.length > 0 && (
                <LinkScrollItineraryStyle className='card'>
                    <div className="header">See Itinerary by Day</div>
                    <div className="body">
                        {itinerary.map((item: any, index: any) => {
                            const order = index + 1;
                            return (
                                <div
                                    className="link"
                                    onClick={() => handleScroll(`itinerary${order}`)}
                                    key={order}
                                >
                                    Day {order} -{' '}
                                    {moment(item?.date, 'YYYY-MM-D').format(
                                        'ddd Do MMMM YYYY',
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </LinkScrollItineraryStyle>
            )}
        </>
    );
};

export default ItineraryScrollComponent;
