import styled from '@emotion/styled';

const CartWrapStyle = styled('div')`
    font-size: 16px;
    line-height: 1.2;

    .itemCart {
        margin-bottom: 16px;
    }
    .viewPayment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        font-size: 20px;
        .name {
            font-weight: 700;
            span {
                font-weight: 400;
            }
        }
        .iconPlus {
            cursor: pointer;
        }
    }

    .group {
        padding: 10px 16px;

        .detail {
            display: flex;
            align-items: flex-start;
            gap: 5px;
            padding: 5px 0;

            .iconDetail {
                color: rgb(18, 184, 151);
                margin-top:1px;
            }

            .titleDetail {
                width: 90%;
                padding-left: 5px;
            }
        }
    }

    .box-total {
        font-size: 16px;
        border-top: 1px solid rgb(220, 220, 220);
        padding-top: 16px;
        margin-top: 16px;
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 7px 0;
            .name {
                font-weight: 700;
            }
            .value {
                font-weight: 900;
            }
            &.total {
                .name {
                    font-size: 18px;
                }
                .value {
                    font-size: 22px;
                }
            }
        }
    }

    &.mini-cart {
        .viewPayment {
            font-size: 16px;
        }
    }

    @media screen and (min-width: 768px) {
        .group {
            padding-left: 48px;
            padding-right: 48px;
        }

        &.mini-cart {
            .group {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }
`;

export default CartWrapStyle;
