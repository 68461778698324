import { PayloadAction } from '@reduxjs/toolkit';
import * as ActionType from '../actions/contentfulAction';

export interface contentfulState {
    contentful: any;
}
const initialState: contentfulState = {
    contentful: {},
};
export const contentfulReducer = (
    state = initialState,
    action: PayloadAction<any>,
) => {
    switch (action.type) {
        case ActionType.SET_CONTENTFUL:
            return {
                contentful: action.payload,
            };
        case ActionType.UPDATE_CONTENTFUL:
            return {
                ...state,
                contentful: { ...state.contentful, ...action.payload },
            };
        default:
            return state;
    }
};
