import React, { useEffect, useState } from 'react';
import NextStepsStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { Col, Row } from 'antd';
import { PHONE_YGT } from 'src/const/common';
import { formatPhoneNumber } from 'src/const/contain';
import { ENV } from 'src/const/evn';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalSendMessage from 'src/components/modalSendMessage';

const NextSteps: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { id: quoteId = '', resConsultant = {} } =
        useSelector(quoteDetailSelector);
    const { telephone } = resConsultant;

    const phone = telephone || PHONE_YGT;
    const phoneCall = () => {
        window.location.href = `tel:${phone}`;
    };

    const handleRedirectToPay = () => {
        window.open(`${ENV.URL_PAYMENT}/quotes/QV${quoteId}`, '_blank');
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <NextStepsStyle className='box-line'>
                <div className="intro-title">Next steps</div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>
                        <div className="box-step">
                            <div className="step-num">1</div>
                            <div>
                                <div className="step-title">Any questions?</div>
                                <div className="step-detail">
                                    Call me on{' '}
                                    <span className="link" onClick={phoneCall}>
                                        {formatPhoneNumber(phone)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div className="box-step">
                            <div className="step-num">2</div>
                            <div>
                                <div className="step-title">Ready to Book?</div>
                                <div className="step-detail">
                                    <span
                                        className="link"
                                        onClick={handleRedirectToPay}
                                    >
                                        Pay your deposit here{' '}
                                    </span>
                                    to secure this quote.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div className="box-step">
                            <div className="step-num">3</div>
                            <div>
                                <div className="step-title">
                                    Need to amend this Quote?
                                </div>
                                <div className="step-detail">
                                    <span className="link" onClick={openModal}>
                                        Let me know what you need,
                                    </span>{' '}
                                    and I'll start working on it for you.
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <div className="box-step">
                            <div className="step-num">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faClock}
                                />
                            </div>
                            <div>
                                <div className="step-title">
                                    This quote is only valid for 48 hours.
                                </div>
                                <div className="step-detail">
                                    Availability is limited, so act quickly to
                                    secure your trip!
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </NextStepsStyle>
            <ModalSendMessage
                onClose={handleClose}
                isVisible={isModalVisible}
            />
        </>
    );
};

export default NextSteps;
