import React, { useEffect } from 'react';
import VersionModalStyle from './style';
import icons from 'src/assets/icon';
import { Table } from 'antd';
import useQueryApi from 'src/hooks/useApiQuery';
import { getAllVersion } from 'src/api/apiGetAllVersion';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
interface VersionModalProps {
    idQuote?: any;
}

const VersionModal: React.FC<VersionModalProps> = ({ idQuote }) => {
    const location = useLocation();
    const containsRevisionHistory =
        location.pathname.includes('revisionHistory');

    const { data, refetch, isLoading } = useQueryApi(
        ['idQuote', idQuote],
        () => getAllVersion(idQuote),
        {
            enabled: !!idQuote,
        },
    );

    useEffect(() => {
        if (idQuote) {
            refetch();
        }
    }, [idQuote, refetch]);

    const dataVersion = (data as { versions: any })?.versions;
    const handleRowClick = (item: any, quoteId?: any) => {
        window.open(`/quoteDetail/introduction?id=${item}`, '_blank');
    };
    const columns = [
        {
            title: 'Quote Title',
            dataIndex: 'quoteTitle',
            key: 'quoteTitle',
            className: 'quoteTitleColumn',
            render: (text: string, record: any) => {
                const quoteTitle = record?.data?.introduction?.title;
                return <div>{quoteTitle}</div>;
            },
        },
        {
            title: 'Departure date',
            dataIndex: 'departureDate',
            key: 'departureDate',
            className: 'departureDateColumn',
            render: (text: string, record: any) => {
                const departureDate = record?.data?.introduction?.departureDate;
                return <div>{moment(departureDate).utc().format('D MMM YYYY')}</div>;
            },
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: 'Version detail',
            dataIndex: 'vertionDetail',
            key: 'vertionDetail',
            className: 'vertionDetailColumn',
            render: (text: string, record: any) => {
                const vertionDetail = record?.data?.detail;
                return <div>{vertionDetail}</div>;
            },
        },
        {
            title: 'Version Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: any) => {
                const updateDate = record?.date;
                return (
                    <div>
                        {record?.status}{' '} 
                        {moment(updateDate).utc().format('DD/MM/YYYY HH:mm')}
                    </div>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'action',
            render: (text: string, record: any) => {
                const idVersion = record?.id;
                const quoteId = record?.data?.introduction?.id;
                return (
                    <div className="viewGroupsActions">
                        <img
                            alt=""
                            className="actionIcon"
                            src={icons.eyeIcon}
                            onClick={() => handleRowClick(idVersion, quoteId)}
                        />
                    </div>
                );
            },
        },
    ];
    return (
        <VersionModalStyle>
            {containsRevisionHistory && <div className='revisionId'>{idQuote}</div>}
            <Table
                dataSource={dataVersion}
                columns={columns}
                pagination={{ pageSize: 5 }}
                rowKey="id"
                rowClassName={(record, index) =>
                    index % 2 === 0 ? 'highlight-row' : ''
                }
                loading={isLoading}
                size='small'
            />
        </VersionModalStyle>
    );
};

export default VersionModal;
