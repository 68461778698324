import React, { useState } from 'react';
import ReviewsStyle from './style';
import { useSelector } from 'react-redux';
import { Flex } from 'antd';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import Carousel from 'src/components/carouselCustom';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalComponent from 'src/components/modalCustom';
import useIsMobile from 'src/hooks/useIsMobile';

interface ReviewItemType {
    comment: string;
    description: string;
    point: string;
    sign: string;
}

const Reviews: React.FC = () => {
    const { reviews } = useSelector(quoteDetailSelector);
    const { golf = [], hotel = [] } = reviews;
    const reviewsData: ReviewItemType[] = [...golf, ...hotel];

    const isMobile: boolean = useIsMobile();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemReview, setItemReview] = useState<ReviewItemType>();

    const handleShowModal = (review: ReviewItemType) => {
        setItemReview(review);
        setIsModalVisible(true);
    };

    const handleHideModal = () => {
        setItemReview(undefined);
        setIsModalVisible(false);
    };

    const renderContentModal = () => {
        const { comment, description, point, sign } = itemReview || {};
        return (
            <ReviewsStyle className="modal-review">
                <div className="title">
                    <div className="point">{point}</div>
                    <div className="comment">{comment}</div>
                </div>
                <div className="description">{description}</div>
                <Flex justify="end">
                    <div className="sign">{sign}</div>
                </Flex>
            </ReviewsStyle>
        );
    };

    return (
        <>
            {' '}
            {reviewsData.length > 0 && (
                <>
                    <ReviewsStyle className="box-line">
                        <div className="intro-title">
                            Why book with Your Golf Travel?
                        </div>
                        <Carousel
                            itemsToShow={isMobile ? 1 : 2}
                            totalLength={reviewsData.length}
                        >
                            {reviewsData?.map(
                                (item: ReviewItemType, index: any) => {
                                    return (
                                        <ReviewItem
                                            key={index}
                                            review={item}
                                            onShowModal={handleShowModal}
                                        />
                                    );
                                },
                            )}
                        </Carousel>
                    </ReviewsStyle>
                    <ModalComponent
                        width={500}
                        visible={isModalVisible}
                        onClose={handleHideModal}
                        content={renderContentModal()}
                    />
                </>
            )}
        </>
    );
};

export default Reviews;

interface ReviewItemProps {
    review: ReviewItemType;
    onShowModal: (review: ReviewItemType) => void;
}
const LENGTH = 78;
const ReviewItem: React.FC<ReviewItemProps> = ({ review, onShowModal }) => {
    const { comment, description, point, sign } = review;
    const shortDescription =
        description?.length > LENGTH
            ? description.substring(0, LENGTH) + '...'
            : description;
    return (
        <div className="item-review">
            <div className="title">
                <div className="point">{point}</div>
                <div className="comment">{comment}</div>
            </div>
            <div className="description">
                {shortDescription}{' '}
                <FontAwesomeIcon
                    className="icon color-success"
                    icon={faQuoteRight}
                />
            </div>
            <Flex align="center" justify="space-between">
                <div className="see-more" onClick={() => onShowModal(review)}>
                    See more
                </div>
                <div className="sign">{sign}</div>
            </Flex>
        </div>
    );
};
