import React, { useCallback, useEffect, useState } from 'react';
import AdditionalInfoPageStyle from './style';
import icons from 'src/assets/icon';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import ReactQuill from 'react-quill';
import {
    setParamsQuoteDetail,
    updateParamsQuoteDetail,
} from 'src/redux/actions/paramsQuoteDetailAction';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['link'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['blockquote', 'code-block'],
    ],
};
const formats = [
    'header',
    'link',
    'script',
    'indent',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'list',
    'blockquote',
    'code-block',
];

const AdditionalInfoPage: React.FC = () => {
    const { additionalInfo = '' } = useSelector(quoteDetailSelector);
    const paramsQuote = useSelector(paramsQuoteDetailSelector);
    const dispatch = useDispatch();
    const [content, setContent] = useState<string>('');
    const [isAddComment, setIsAddComment] = useState(false);
    const isEditPage = useSelector(toggleEditQuoteSelector);

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const handleAddComment = () => {
        setIsAddComment(true);
        setContent(paramsQuote?.additionalInfo || additionalInfo);
    };

    const handleCancelComment = () => {
        setIsAddComment(false);
        if (paramsQuote?.additionalInfo) {
            delete paramsQuote?.additionalInfo;
            dispatch(setParamsQuoteDetail(paramsQuote));
        }
    };

    const getText = (html: string) => {
        return html?.replace(/<[^>]*>/g, '');
    };

    const handleChange = useCallback(
        (value: string) => {
            setContent(value);
            dispatch(updateParamsQuoteDetail({ additionalInfo: value }));
        },
        [dispatch],
    );

    const renderContent = () => (
        <AdditionalInfoPageStyle className={isEditPage ? 'edit' : ''}>
            <div className="title">Additional Information</div>
            {isAddComment ? renderBoxEdit() : renderText()}
            {isEditPage && renderComment()}
        </AdditionalInfoPageStyle>
    );

    const renderText = () => (
        <div
            className="box-value"
            dangerouslySetInnerHTML={{
                __html: paramsQuote?.additionalInfo || additionalInfo,
            }}
        ></div>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={handleAddComment}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    const renderBoxEdit = () => (
        <>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={content}
                onChange={handleChange}
            />
            <div className="btn-cancel" onClick={handleCancelComment}>
                Cancel
            </div>
        </>
    );

    return (
        <>
            {isEditPage ? (
                renderContent()
            ) : (
                <>{getText(additionalInfo) && renderContent()}</>
            )}
        </>
    );
};

export default AdditionalInfoPage;
