import styled from '@emotion/styled';

const CopyLinkVersionStyle = styled('div')`
    .ant-input-search-button {
        background-color: #12b897 !important;
    }
    .contentShare {
        display: flex;
        align-items: center;
        margin-top: 24px;
    }
    .inputCopy {
        margin-top: 20px;
    }
    .faCircleInfo {
        color: #12b897;
        font-size: 15px;
        font-weight: 900;
        margin-right: 6px;
    }
`;

export default CopyLinkVersionStyle;
