import React, { useCallback, useEffect, useState } from 'react';
import TitleQuoteDetailStyle from './style';
import { Input, Popover } from 'antd';
import { updateParamsQuoteDetail } from 'src/redux/actions/paramsQuoteDetailAction';
import { useDispatch } from 'react-redux';

interface TitleQuoteDetailProps {
    isEdit: boolean;
    titleQuoteDetail: string;
}

const TitleQuoteDetail: React.FC<TitleQuoteDetailProps> = ({
    isEdit,
    titleQuoteDetail,
}) => {
    const dispatch = useDispatch();
    const [isShowInput, setIsShowInput] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        setIsShowInput(false);
        setInputValue(titleQuoteDetail)
    }, [isEdit, titleQuoteDetail]);

    const handleShowInput = () => {
        setIsShowInput(!isShowInput);
    };

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            setInputValue(value);
            dispatch(updateParamsQuoteDetail({title: value}))
        },
        [dispatch],
    );

    const renderViewTitle = () => <div>{titleQuoteDetail}</div>;

    const renderPopover = () => (
        <Popover content={'Click to edit'}>
            <div className="edit-title" onClick={handleShowInput}>
                {titleQuoteDetail}
            </div>
        </Popover>
    );

    const renderInputTitle = () => (
        <div>
            <div className="max-length">{inputValue.length}/100</div>
            <Input
                className="input"
                variant="borderless"
                defaultValue={titleQuoteDetail}
                onChange={handleInputChange}
                maxLength={100}
                status={inputValue ? "":"error"}
            />
        </div>
    );

    return (
        <TitleQuoteDetailStyle>
            {isEdit
                ? isShowInput
                    ? renderInputTitle()
                    : renderPopover()
                : renderViewTitle()}
        </TitleQuoteDetailStyle>
    );
};

export default TitleQuoteDetail;
