import { PayloadAction } from '@reduxjs/toolkit';
import * as ActionType from '../actions/toggleEditQuoteAction';

export interface contentfulState {
    isEdit: boolean;
}
const initialState: contentfulState = {
    isEdit: false,
};
export const toggleEditQuoteReducer = (
    state = initialState,
    action: PayloadAction<boolean>,
) => {
    switch (action.type) {
        case ActionType.TOGGLE_EDIT_QUOTE:
            return {
                isEdit: !action.payload,
            };
        default:
            return state;
    }
};
