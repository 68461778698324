import {
    faBed,
    faCar,
    faFlag,
    faGolfBallTee,
    faMap,
    faPlane,
    faTaxi,
    faTrophy,
    faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';
import icons from 'src/assets/icon';
import ViewAndEditPrice from '../viewAndEditPrice';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';

interface CartItemProps {
    item: any;
    tourName?: any;
    numOfGroups: number;
    isMiniCart?: boolean;
}

const CartItem: React.FC<CartItemProps> = ({
    item,
    tourName,
    numOfGroups,
    isMiniCart = true,
}) => {
    const [isShow, setIsShow] = useState<boolean>(true);

    const { dbPriceGroups, unallocatedAccommodation } =
        useSelector(quoteDetailSelector);

    const { group, items } = item;
    const {
        price,
        carHire = [],
        flight = [],
        transfer = [],
        accommodations = [],
        golfRounds = 0,
        hasTourEvent = false,
        totalPerson,
        returnAirportTransfer = false,
        golf = [],
        hasGolfExtra = false,
        hasFlightExtra = false,
    } = items;

    const flightDepartureName = flight[0]?.departure?.name;
    const flightArrivalName = flight[0]?.arrival?.name;

    const carHirePickUp = carHire[0]?.pickUp?.location || '';
    const carHireNights = carHire[0]?.nights || 0;

    const { hasSpa = false, extra = [] } = accommodations[0] || {};
    const nbrCodeExtra = extra[0]?.nbrCode;

    const pricePerPerson = dbPriceGroups[group] || price;

    const results = transfer.map((tr: any) => {
        if (tr.carType.includes('Airport')) {
            return 'Airport';
        } else if (tr.carType.includes('Golf')) {
            return 'Golf';
        } else if (tr.carType.includes('Hotel')) {
            return 'Hotel';
        } else {
            return '';
        }
    });

    const showDetailPrice = () => {
        setIsShow(!isShow);
    };

    const renderUnallocatedAccommodation = () => (
        <>
            {unallocatedAccommodation && (
                <div className="detail">
                    <FontAwesomeIcon className="iconDetail" icon={faBed} />
                    <div className="titleDetail">
                        Accommodation as per your itinerary
                    </div>
                </div>
            )}
        </>
    );

    const renderAccommodation = () => (
        <>
            {accommodations?.map((ac: any, index: any) => {
                const extra = ac?.extra;
                const duration = ac?.duration;
                return (
                    <div key={index}>
                        <div className="detail">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faUserGroup}
                            />
                            <div className="titleDetail">
                                {totalPerson}{' '}
                                {Number(totalPerson) === 1 && 'person'}{' '}
                                {Number(totalPerson) > 1 && 'people'} arriving
                                on{' '}
                                {moment(ac?.startDate || 0)
                                    .utc()
                                    .format('DD/MM/YYYY')}
                            </div>
                        </div>
                        <div className="detail">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faMap}
                            />
                            <div className="titleDetail">
                                Staying at {ac?.name}, {ac?.roomType}
                                {`${
                                    ac?.singleOccupancy
                                        ? ' (Single Occupancy)'
                                        : ''
                                }`}
                            </div>
                        </div>
                        <div className="detail" key={index}>
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faBed}
                            />
                            <div className="titleDetail">
                                {duration}{' '}
                                {Number(duration) > 1 ? 'Nights' : 'Night'}
                                {ac?.boardBasis ? `, ${ac?.boardBasis}` : ''}
                                {extra?.map((itemExtras: any, indexEx: any) => {
                                    return (
                                        <span key={indexEx}>
                                            {isMiniCart ? (
                                                <>
                                                    {itemExtras?.nbrCode !==
                                                        ac?.roomType &&
                                                    itemExtras?.nbrCode !==
                                                        ac?.boardBasis
                                                        ? `, ${itemExtras?.nbrCode}`
                                                        : ''}
                                                </>
                                            ) : (
                                                <>
                                                    {itemExtras?.nbrCode !==
                                                        ac?.roomType &&
                                                    itemExtras?.nbrCode !==
                                                        ac?.boardBasis
                                                        ? `, ${itemExtras?.nbrCode}`
                                                        : ''}
                                                    {itemExtras
                                                        ?.extraComments[0]
                                                        ? `, ${itemExtras?.extraComments[0]}`
                                                        : ''}
                                                </>
                                            )}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );

    const renderDetailCart = () => (
        <>
            {flight?.length > 0 && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon icon={faPlane} />
                    </div>
                    <div className="titleDetail">
                        {' '}
                        {flight?.length > 1
                            ? 'Return Flights'
                            : 'Flight'} from {flightDepartureName} to{' '}
                        {flightArrivalName}
                    </div>
                </div>
            )}
            {returnAirportTransfer && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon icon={faPlane} />
                    </div>
                    <div className="titleDetail"> Return airport transfers</div>
                </div>
            )}
            {hasFlightExtra && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon icon={faPlane} />
                    </div>
                    <div className="titleDetail">
                        Flight extras as detailed in your itinerary
                    </div>
                </div>
            )}
            {carHire?.length > 0 && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon icon={faCar} />
                    </div>
                    <div className="titleDetail">
                        {' '}
                        Car hire pick-up from {carHirePickUp} for{' '}
                        {carHireNights}{' '}
                        {Number(carHireNights) > 1 ? 'days' : 'day'}
                    </div>
                </div>
            )}

            {renderUnallocatedAccommodation()}

            {renderAccommodation()}

            {hasSpa && nbrCodeExtra !== 'Spa Package' && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon icon={faUserGroup} />
                    </div>
                    <div className="titleDetail"> Spa package</div>
                </div>
            )}
            {golf?.map((item: any, index: any) => {
                return (
                    <div key={index}>
                        <div className="detail">
                            <div className="iconDetail">
                                <FontAwesomeIcon
                                    icon={tourName ? faGolfBallTee : faFlag}
                                />
                            </div>
                            <div className="titleDetail">
                                {' '}
                                {item?.rounds}{' '}
                                {item?.rounds > 1 ? 'Rounds' : 'Round'} of Golf
                                at {item?.serviceDescription}
                                {item?.documentDescription
                                    ? `, ${item?.documentDescription}`
                                    : ''}
                            </div>
                        </div>
                    </div>
                );
            })}

            {hasGolfExtra && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon
                            icon={tourName ? faGolfBallTee : faFlag}
                        />
                    </div>
                    <div className="titleDetail">
                        {' '}
                        Golf extras as detailed in your itinerary
                    </div>
                </div>
            )}

            {transfer?.length > 0 && (
                <div className="detail">
                    <div className="iconDetail">
                        <FontAwesomeIcon icon={faTaxi} />
                    </div>
                    <div className="titleDetail">
                        {results[1]} {results[1] ? 'transfers' : 'Transfers'} as
                        detailed in your itinerary
                    </div>
                </div>
            )}
        </>
    );

    const renderDetailMiniCart = () => (
        <>
            {renderUnallocatedAccommodation()}
            {renderAccommodation()}
            {golfRounds > 0 && (
                <div className="detail">
                    {tourName ? (
                        <FontAwesomeIcon
                            className="iconDetail"
                            icon={faGolfBallTee}
                        />
                    ) : (
                        <FontAwesomeIcon className="iconDetail" icon={faFlag} />
                    )}
                    <div className="titleDetail">
                        {' '}
                        {golfRounds} {golfRounds > 1 ? 'Rounds' : 'Round'} of
                        Golf
                    </div>
                </div>
            )}

            {flight.length > 0 && (
                <div className="detail">
                    <FontAwesomeIcon className="iconDetail" icon={faPlane} />
                    <div className="titleDetail">Flights as detailed</div>
                </div>
            )}

            {carHire.length > 0 && (
                <div className="detail">
                    <FontAwesomeIcon className="iconDetail" icon={faCar} />
                    <div className="titleDetail">Car hire as detailed</div>
                </div>
            )}

            {transfer.length > 0 && (
                <div className="detail">
                    <FontAwesomeIcon className="iconDetail" icon={faTaxi} />
                    <div className="titleDetail">Transfers as detailed</div>
                </div>
            )}
        </>
    );

    return (
        <div className="itemCart">
            <div className="viewPayment">
                <div className="groupComponent">
                    <div className="name">
                        {Number(numOfGroups) > 1 ? (
                            <>
                                {group} <span>(price per person)</span>
                            </>
                        ) : (
                            'Price per person'
                        )}
                    </div>
                </div>
                <ViewAndEditPrice
                    groupName={group}
                    price={pricePerPerson}
                    isEdit={isMiniCart}
                />
                {isMiniCart && (
                    <img
                        alt=""
                        className="iconPlus"
                        src={isShow ? icons.minusIcon : icons.plusIcon}
                        onClick={showDetailPrice}
                    />
                )}
            </div>
            {isShow && (
                <div className="group">
                    {hasTourEvent && (
                        <div className="detail">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faTrophy}
                            />
                            <div className="titleDetail">{tourName}</div>
                        </div>
                    )}
                    {isMiniCart ? renderDetailMiniCart() : renderDetailCart()}
                </div>
            )}
        </div>
    );
};

export default CartItem;
