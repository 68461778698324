import React, { useState } from 'react';
import ItineraryPageStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import { Button, Carousel } from 'antd';
import { itinerarySelector } from 'src/redux/selectors/itinerarySelector';
import { modifyURL } from 'src/const/contain';
import ModalChoseMainImage from 'src/components/modalChoseImage';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import AdditionalInfoPage from './additionalInfo';
import ItineraryItem from './itineraryItem';
import NotFound from 'src/components/notFoundData';

const ItineraryPage: React.FC = () => {
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const { tourName = '', isEvent } = useSelector(quoteDetailSelector);
    const {
        images = [],
        itinerary = [],
        componentComments = [],
    } = useSelector(itinerarySelector);
    const params = useSelector(paramsQuoteDetailSelector);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const listImages = params?.images || images;

    const {
        quoteWhyBookWithYgtTheMasters,
        quoteWhyBookWithYgtTheRyderCup,
        quoteWhyBookWithYgtTheOpen,
        quoteWhyBookWithYgtTheUsOpen,
        quoteWhyBookWithYgtSolheimCup,
        quoteWhyBookWithYgtThePlayersChamps,
        quoteWhyBookWithYgtRaceToDubai,
        quoteWhyBookWithYgtPgaTour,
        quoteWhyBookWithYgtDpWorldTour,
    } = useSelector(contentfulSelector);

    const getDataWhyBook = (tour: string) => {
        let dataWhyBook: any;
        switch (true) {
            case tour.includes('Masters'):
                dataWhyBook = quoteWhyBookWithYgtTheMasters;
                break;
            case tour.includes('Ryder'):
                dataWhyBook = quoteWhyBookWithYgtTheRyderCup;
                break;
            case tour.includes('The Open'):
                dataWhyBook = quoteWhyBookWithYgtTheOpen;
                break;
            case tour.includes('US Open'):
                dataWhyBook = quoteWhyBookWithYgtTheUsOpen;
                break;
            case tour.includes('Solheim'):
                dataWhyBook = quoteWhyBookWithYgtSolheimCup;
                break;
            case tour.includes('Players'):
                dataWhyBook = quoteWhyBookWithYgtThePlayersChamps;
                break;
            case tour.includes('Dubai'):
                dataWhyBook = quoteWhyBookWithYgtRaceToDubai;
                break;
            case tour.includes('PGA'):
                dataWhyBook = quoteWhyBookWithYgtPgaTour;
                break;
            case tour.includes('DP'):
                dataWhyBook = quoteWhyBookWithYgtDpWorldTour;
                break;
            default:
                dataWhyBook = quoteWhyBookWithYgtTheMasters;
                break;
        }

        const formatData = dataWhyBook?.content[0]?.content || [];

        return formatData.map(
            (item: any) => item?.content[0]?.content[0]?.value,
        );
    };

    const dataWhyBookEvent = getDataWhyBook(tourName);

    const allCommentsAccommodation = itinerary.flatMap((dateDetail: any) =>
        dateDetail?.detail?.filter(
            (item: any) =>
                item?.type === 'Accommodation' &&
                item?.comments &&
                item.comments.length > 0,
        ),
    );

    const allCommentsGolf = itinerary.flatMap((dateDetail: any) =>
        dateDetail?.detail?.filter(
            (item: any) =>
                item?.type === 'Golf' &&
                item?.comments &&
                item.comments.length > 0,
        ),
    );

    const getUniqueComments = (comments: any[]) => {
        const uniqueComments: any[] = [];
        comments.forEach((item: any) => {
            if (
                !uniqueComments
                    .map((item: any) => item?.name)
                    .includes(item?.name)
            ) {
                uniqueComments.push(item);
            }
        });

        return uniqueComments;
    };

    const autoFormatText = (text: string) => {
        const urlRegex =
            /(?:https?:\/\/)?(?:www\.)?([\w.-]+\.[a-z]{2,}(?:\/[^\s]*)?)/g;
        let formattedText = text.replace(urlRegex, (match, domainPath) => {
            const fullUrl = match.startsWith('http')
                ? match
                : `https://${match}`;
            return `<a href="${fullUrl}" target="_blank">${domainPath}</a>`;
        });

        formattedText = formattedText
            .replace(/\r\n/g, '<br />')
            .replace(/\n/g, '<br />');

        formattedText = formattedText.replace(/^\*+\s*/gm, '');

        return formattedText;
    };

    const isViewPleaseRead =
        componentComments.length > 0 ||
        allCommentsAccommodation.length > 0 ||
        allCommentsGolf.length > 0;

    const handleShowModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const renderCarouselImage = () => (
        <>
            {isEditPage && (
                <Button
                    type="primary"
                    className="btn-choose-image"
                    onClick={handleShowModal}
                    ghost
                >
                    Choose image
                </Button>
            )}
            <Carousel autoplay arrows={listImages.length > 1} style={{marginBottom: "24px"}} className='carousel-box'>
                {listImages?.map((item: any, index: any) => {
                    return (
                        <div className="carousel-item" key={index}>
                            <img alt="" src={modifyURL(item, 1000)} />
                        </div>
                    );
                })}
            </Carousel>
        </>
    );

    const renderWhyBook = () => (
        <>
            {isEvent && dataWhyBookEvent.length > 0 && (
                <div className="card">
                    <div className="title-card">Why Book With YGT</div>
                    <div className="why-book">
                        {dataWhyBookEvent.map((item: any, index: any) => (
                            <div className="item" key={index}>
                                <FontAwesomeIcon
                                    className="icon-check"
                                    icon={faCircleCheck}
                                />
                                <p>{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );

    const renderPleaseRead = () => (
        <div className="card please-read">
            <div className="title-card">Please Read</div>
            <div className="sub-card">
                Important Information About Your Proposed Trip
            </div>
            {getUniqueComments(allCommentsAccommodation).map(
                (item: any, index: any) => (
                    <div className="please-box" key={index}>
                        <div className="name">{item?.name}</div>
                        <ul>
                            {item?.comments.map(
                                (comment: any, commentIndex: any) => (
                                    <li
                                        key={commentIndex}
                                        dangerouslySetInnerHTML={{
                                            __html: autoFormatText(
                                                comment?.text,
                                            ),
                                        }}
                                    />
                                ),
                            )}
                        </ul>
                    </div>
                ),
            )}
            {getUniqueComments(allCommentsGolf).map((item: any, index: any) => (
                <div className="please-box" key={index}>
                    <div className="name">{item?.serviceDescription}</div>
                    <ul>
                        {item?.comments.map(
                            (comment: any, commentIndex: any) => (
                                <li
                                    key={commentIndex}
                                    dangerouslySetInnerHTML={{
                                        __html: autoFormatText(comment?.text),
                                    }}
                                />
                            ),
                        )}
                    </ul>
                </div>
            ))}
            {componentComments.length > 0 && (
                <div className="please-box">
                    <div className="name">Additional Comments</div>
                    <ul>
                        {componentComments.map(
                            (comment: any, commentIndex: any) => (
                                <li
                                    key={commentIndex}
                                    dangerouslySetInnerHTML={{
                                        __html: autoFormatText(comment?.text),
                                    }}
                                />
                            ),
                        )}
                    </ul>
                </div>
            )}
        </div>
    );

    const renderItinerary = () => (
        <>
            {itinerary
                .filter((item: any) =>
                    isEvent ? true : item?.detail.length > 0,
                )
                .map((item: any, index: any) => (
                    <ItineraryItem
                        date={item?.date || ''}
                        details={item?.detail || []}
                        order={isEvent ? (index + 1).toString() : ''}
                        key={index}
                    />
                ))}
        </>
    );

    return (
        <>
            <ItineraryPageStyle>
                {renderCarouselImage()}
                {itinerary.length > 0 ? (
                    <>
                        {renderItinerary()}
                        {isEvent && <AdditionalInfoPage />}
                        {renderWhyBook()}
                        {isViewPleaseRead && renderPleaseRead()}
                    </>
                ) : (
                    <div className="card" style={{ marginTop: 0 }}>
                        <NotFound content={<div>No data to display</div>} />
                    </div>
                )}
            </ItineraryPageStyle>

            {isModalVisible && (
                <ModalChoseMainImage
                    isVisible={isModalVisible}
                    onClose={handleCloseModal}
                    valueImages={listImages}
                    isMulti={true}
                />
            )}
        </>
    );
};

export default ItineraryPage;
