import styled from '@emotion/styled';

const ModalSendMessageStyle = styled('div')`
    .textArea {
        margin: 16px 0 24px;
    }
    .btnSend {
        font-size: 16px;
        font-weight: 900;
        height: 50px;
        &:not(:disabled) {
            background-color: rgb(18, 184, 151) !important;
        }
    }
`;

export default ModalSendMessageStyle;
