import styled from '@emotion/styled';
import '../../../assets/colors.scss';
const TermsAndConditionsPageStyle = styled('div')`
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(156, 171, 194, 0.4) 4px 4px 20px 0px;
    padding: 32px;
    margin-bottom: 2rem;
    line-height: 1.4;

    .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
    }

    ul{
        list-style: disc;
    }

    h2, p{
    margin-bottom:16px;}

    @media screen and (max-width: 767px) {
        padding: 24px;
    }
`;
export default TermsAndConditionsPageStyle;
