import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Spin } from 'antd';
import ModalConfirmSaveQuoteStyle from './style';
import { useSelector, useDispatch } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { updateQuoteDetail } from 'src/redux/actions/quoteDetailAction';
import ModalComponent from '../modalCustom';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { apiUpdateQuote } from 'src/api/apiUpdateQuote';
import { toggleEditQuote } from 'src/redux/actions/toggleEditQuoteAction';
import { setParamsQuoteDetail } from 'src/redux/actions/paramsQuoteDetailAction';
import { setItinerary, updateItinerary } from 'src/redux/actions/itineraryAction';
import useQueryApi from 'src/hooks/useApiQuery';
import { getItinerary } from 'src/api/apiGetItinerary';

interface ModalConfirmSaveQuoteProps {
    onClose: () => void;
    isVisible: boolean;
}

const ModalConfirmSaveQuote: React.FC<ModalConfirmSaveQuoteProps> = ({
    onClose,
    isVisible,
}) => {
    const dispatch = useDispatch();
    const { title, id, dbPriceGroups = {} } = useSelector(quoteDetailSelector);
    const params = useSelector(paramsQuoteDetailSelector);
    const [isLoading, setIsLoading] = useState(false);

    const { refetch: refetchItinerary } = useQueryApi(
        ['idItinerary', id],
        () => getItinerary(id),
        {
            enabled: false,
        },
    );

    const formatParams = () => {
        if (!params.priceGroups) {
            return params;
        }
        let newParams = {
            ...params,
            ...{
                dbPriceGroups: Object.assign(dbPriceGroups, params.priceGroups),
            },
        };

        delete newParams.priceGroups;
        return newParams;
    };

    const handleUpdateQuote = async () => {
        try {
            setIsLoading(true);
            await apiUpdateQuote(id, params);
            if (params?.images) {
                dispatch(updateItinerary({images: params?.images}))
            }
            if (params?.additionalInfo) {
                dispatch(updateItinerary({additionalInfo: params?.additionalInfo}))
            }
            if (params?.itineraryComments && params?.itineraryComments.length > 0) {
                const res = await refetchItinerary();
                dispatch(setItinerary(res?.data || {}));
            }
            setIsLoading(false);
            onClose();
            toast.success('Your updates have been saved.');
            dispatch(updateQuoteDetail(formatParams()));
            dispatch(setParamsQuoteDetail({}));
            dispatch(toggleEditQuote(true));
        } catch (error) {
            setIsLoading(false);
        }
    };
    const renderContent = () => (
        <ModalConfirmSaveQuoteStyle>
            <div className="contentModal">
                Are you sure to save the quote{' '}
                <span className="quoteTitle">{title}</span>
            </div>
            <div className="groupBtn">
                <Button
                    type="primary"
                    className="btnEdit"
                    onClick={onClose}
                    ghost
                    disabled={isLoading}
                >
                    Back to Edit
                </Button>
                <Spin spinning={isLoading}>
                    <Button
                        type="primary"
                        className="btnSend"
                        onClick={handleUpdateQuote}
                    >
                        Save
                    </Button>
                </Spin>
            </div>
        </ModalConfirmSaveQuoteStyle>
    );

    return (
        <ModalComponent
            width={500}
            visible={isVisible}
            onClose={onClose}
            title="Confirmation"
            content={renderContent()}
        />
    );
};

export default ModalConfirmSaveQuote;
