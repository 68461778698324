import styled from '@emotion/styled';

const ModalMainImageStyle = styled('div')`
    .ant-tabs .ant-tabs-tab {
        &.ant-tabs-tab-active {
            border-color: rgb(18, 184, 151) !important;
            border-bottom-color: #ffffff !important;
            .ant-tabs-tab-btn {
                color: rgb(18, 184, 151) !important;
            }
        }
        &:hover {
            .ant-tabs-tab-btn {
                color: rgb(18, 184, 151) !important;
            }
        }
    }
    .wrap-image {
        height: 450px;
        overflow-y: auto;
        scrollbar-width: none;
        padding: 16px;
        .box-img {
            .img-item {
                position: relative;
                vertical-align: middle;
                transition: all 0.5s;
                cursor: pointer;
                box-shadow: rgba(156, 171, 194, 0.4) 4px 4px 20px 0px;
                border-radius: 6px;
                overflow: hidden;

                &::before {
                    display: block;
                    content: '';
                    width: 100%;
                    padding-top: 52%;
                }
                img {
                    right: 0;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    top: 0;
                    position: absolute;
                    object-fit: cover;
                }

                .name {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    padding: 5px;
                    line-height: 1.2;
                    background: rgb(255 255 255 / 50%);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size:14px;
                }
                &.selected {
                    border: 3px solid rgb(18, 184, 151);
                }
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .select {
        width: 200px;
    }

    .groupBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        gap: 16px;
    }
`;

export default ModalMainImageStyle;
