import styled from '@emotion/styled';

const ViewAndEditPriceStyle = styled('div')`
    font-size: inherit;
    font-weight: 900;
    color: #000;

    .edit-title {
        padding: 5px;
        border: 2px solid rgb(18, 184, 151);
    }
    .input {
        height: 34px;
        padding: 5px;
        border: 2px solid rgb(18, 184, 151);
        font-size: inherit;
        font-weight: 900;
        border-radius: 0;
        &.ant-input-status-error{
            border-color: #ff4d4f
        }
    }

    @media screen and (max-width: 767px) {
        // font-size: 16px;
    }
`;

export default ViewAndEditPriceStyle;
