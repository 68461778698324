import styled from '@emotion/styled';

const TransferCardStyle = styled('div')`
    position: relative;

    &.edit {
        border: 2px solid rgb(18, 184, 151);
    }

    .iconAddComment {
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: -25px;
        margin-top: -25px;
        cursor: pointer;
        transition: all 0.2s;
        z-index: 1;
        &:hover {
            transform: scale(1.1);
        }
    }
    .item-flight {
        margin-bottom: 10px;
        .icon-detail {
            color: rgb(18, 184, 151);
            margin-top: 3px;
        }
        .location {
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 768px) {
    }
`;

export default TransferCardStyle;
