import styled from '@emotion/styled';

const LinkScrollItineraryStyle = styled('div')`
    .header {
        color: #000;
        font-weight: 700;
    }
    .body {
        margin-top: 15px;
    }
    .link {
        color: #1a91ff;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
        margin-bottom: 10px;
    }
`;

export default LinkScrollItineraryStyle;
