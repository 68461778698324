import styled from '@emotion/styled';

const TitleQuoteDetailStyle = styled('div')`
    overflow-wrap: break-word;
    .box-popover {
        padding: 10px;
        border: 2px solid rgb(18, 184, 151);
        margin-bottom: 20px;
    }

    .quill {
        margin-bottom: 20px;
    }
    .max-length {
        color: rgb(18, 184, 151);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding: 5px 10px;
        border: 1px solid;
        display: table;
        margin-left: auto;
        margin-top: -15px;
    }
    .box-value {
        margin-bottom: 20px;
    }

    @media screen and (min-width: 992px) {
        .box-value {
            width: calc(100% - 100px);
        }
    }
`;

export default TitleQuoteDetailStyle;
