import { AnyAction } from 'redux';
import * as ActionType from '../actions/contenfullAction';

export interface contenFullState {
    contenfull: any;
}
const initialState: contenFullState = {
    contenfull: {},
};
export const contenfullReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.UPDATE_CONTENFULL_SUCCESS:
            return {
                ...state,
                contenfull: { ...state.contenfull, ...action.payload },
            };
        default:
            return state;
    }
};
