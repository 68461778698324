import styled from '@emotion/styled';

const HotelCardStyle = styled('div')`
    position: relative;

    &.edit {
        border: 2px solid rgb(18, 184, 151);
    }

    .iconAddComment {
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: -25px;
        margin-top: -25px;
        cursor: pointer;
        transition: all 0.2s;
        z-index: 1;
        &:hover {
            transform: scale(1.1);
        }
    }

    .box-hotel {
        display: flex;
        gap: 24px;

        .box-img {
            min-width: 185px;
            max-height: 185px;
            position: relative;
            &::before {
                display: block;
                content: '';
                width: 100%;
                padding-top: 100%;
            }
            img {
                right: 0;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                top: 0;
                position: absolute;
                object-fit: cover;
                border-radius: 6px;
            }
        }

        .description {
            color: rgb(0, 0, 0);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .country {
                font-weight: 500;
            }
            .duration {
                margin: 10px 0;
            }

            .room {
                color: rgb(79, 79, 79);
                display: flex;
                gap: 10px;
                .iconDetail {
                    color: rgb(18, 184, 151);
                    margin-top: 3px;
                }
                & + .room {
                    margin-top: 10px;
                }
            }

            .btnSeeDetail {
                color: rgb(26, 145, 255);
                font-weight: 700;
                text-decoration-line: underline;
                cursor: pointer;
                display: table;
                margin-left: auto;
                margin-top: 10px;
            }
        }
    }

    &.modal {
        .box-img {
            position: relative;
            margin-bottom:12px;
            &::before {
                display: block;
                content: '';
                width: 100%;
                padding-top: 50%;
            }
            img {
                right: 0;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                top: 0;
                position: absolute;
                object-fit: cover;
                border-radius: 6px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .box-hotel {
            flex-direction: column;

            .box-img {
                max-height: none;
                min-width: auto;
                margin: -16px -16px 0;
                &::before {
                    padding-top: 50%;
                }
                img {
                    border-radius: 6px 6px 0 0;
                }
            }

            .description {
                margin-bottom: 16px;
            }
        }
    }
`;

export default HotelCardStyle;
