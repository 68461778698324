import React from 'react';
import ViewYourMasterComponentStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

interface YourMasterProps {
    atAGlance: any;
}

const YourMasterComponent: React.FC<YourMasterProps> = ({ atAGlance }) => {
    const { itineraryComments = [], tourName } = atAGlance || {};
    const result = tourName?.split(' - ')[0];

    return (
        <div>
            {itineraryComments.length > 0 && (
                <ViewYourMasterComponentStyle className="card">
                    <div className="header">Your {result} At A Glance</div>
                    <div className="body">
                        {itineraryComments?.map((item: any, index: any) => {
                            let icon = faTrophy;
                            return (
                                <div className="detailAtAGlance" key={index}>
                                    <div className="centerIconAtAGlance">
                                        <FontAwesomeIcon
                                            className="iconDetailAtAGlance"
                                            icon={icon}
                                        />
                                    </div>
                                    <div className="titleDetailAtAGlance">
                                        {item}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </ViewYourMasterComponentStyle>
            )}
        </div>
    );
};

export default YourMasterComponent;
