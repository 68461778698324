import React, { useEffect, useState } from 'react';
import PricingAndBookingPageStyle from './style';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CartWrap from 'src/components/cartWrap';
import ReadyToBookComponent from 'src/components/readyToBook';
import CommentComponent from 'src/components/comment';
import MainImageComponent from 'src/components/mainImageComponent';
import useIsMobile from 'src/hooks/useIsMobile';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';

const PricingAndBookingPage: React.FC = () => {
    const { id: quoteId = '', priceAgentComment = '' } =
        useSelector(quoteDetailSelector);

    const isEdit = useSelector(toggleEditQuoteSelector);

    const [isAddComment, setIsAddComment] = useState(false);

    const isMobile: boolean = useIsMobile();

    const paramsQuote = useSelector(paramsQuoteDetailSelector);

    useEffect(() => {
        setIsAddComment(false);
    }, [isEdit]);

    const renderCart = () => (
        <div className={isEdit ? 'card edit' : 'card'}>
            <div className="validTitle">Your quote is valid for 48 hours.</div>
            <div className="title">Quote Reference: {quoteId}</div>
            <CartWrap isMiniCart={false} />
            {isEdit && renderComment()}
        </div>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return (
        <>
            {!isMobile && <MainImageComponent />}
            <PricingAndBookingPageStyle>
                {renderCart()}
                <CommentComponent
                    isEdit={isAddComment}
                    initialValue={
                        paramsQuote?.priceAgentComment || priceAgentComment
                    }
                    onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                />
                <ReadyToBookComponent />
                <div className="golfLogo">
                    <img alt="" src={images.awardGoflLogo} />
                </div>
            </PricingAndBookingPageStyle>
        </>
    );
};

export default PricingAndBookingPage;
