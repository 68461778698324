import styled from '@emotion/styled';

const ListQuotesComponent = styled('div')`
    .box-search {
        margin-bottom: 16px;
        .ant-select {
            height: 40px;
            .ant-select-selector {
                border-radius: 0;
                min-width: 150px;
            }
        }
        .ant-input-compact-last-item {
            border-radius: 0;
            width: 320px;
        }

        .field-search {
            width: 205px;
            .ant-select-selector {
                background: #deebff;
            }
        }
    }

    .ant-table-pagination {
        position: relative;
        .ant-pagination-total-text {
            position: absolute;
            left: 0;
            top: 0;
        }
        .ant-pagination-options {
            position: absolute;
            left: 125px;
            top: 0;
        }
    }

    .ant-table-wrapper {
        .ant-table {
            scrollbar-color: auto;
            .ant-table-cell-scrollbar {
                box-shadow: none;
            }
        }
        .ant-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
        }
    }

    .ant-table-container {
        .ant-table-header {
            border-radius: 0px !important;
        }
        .ant-table-cell {
            border-radius: 0px !important;
        }
        .ant-table-thead {
            th {
                background-color: rgb(18, 184, 151);
                color: #fff;
                font-weight: 400;
                &::before {
                    display: none;
                }
            }
        }

        .highlight-row .ant-table-cell {
            background: #f7faff;
        }

        .box-action {
            .ant-btn-link {
                padding: 0;
                height: auto;
                position: relative;
                top: 3px;
            }
        }
    }

    .text-clear {
        color: #006699;
        cursor: pointer;
        margin-top: 10px;
    }
`;

export default ListQuotesComponent;
