import React, { useEffect, useState, Fragment } from 'react';
import GolfCardStyle from './style';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag,
    faGolfBall,
    faGolfBallTee,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { modifyURL } from 'src/const/contain';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CommentComponent from '../comment';
import { Flex } from 'antd';
import ModalComponent from '../modalCustom';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { marked } from 'marked';
import parse from 'html-react-parser';
interface GolfCardProps {
    data: any;
}

const GolfCardComponent: React.FC<GolfCardProps> = ({ data }) => {
    const {
        image,
        agentComments = [],
        description,
        rounds,
        serviceDescription,
        documentDescription,
        detailRound = [],
        buggies = [],
    } = data;

    const title = `Golf at ${serviceDescription}`;
    const totalPassengers = detailRound.reduce(
        (total: any, round: any) => total + Number(round.numOfPassenger),
        0,
    );
    const agentComment = agentComments.length > 0 ? agentComments[0] : {};
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const [isAddComment, setIsAddComment] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { itineraryComments = [] }: any = useSelector(
        paramsQuoteDetailSelector,
    );

    const updateComment = itineraryComments.find(
        (comment: any) =>
            comment?.itemId === data?.id &&
            comment?.itineraryDate === data?.itineraryDate,
    )?.comment;

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const urlImage = image ? modifyURL(image, 600) : images.golfDefault;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const formatTime = (time?: string) => {
        const utcTime = moment.utc(time);
        const formattedTime = utcTime.format('HH:mm');
        if (formattedTime === '00:00') {
            return 'Time TBC';
        }
        return utcTime.format('HH:mm');
    };

    const formatDate = (time?: string) => {
        return moment.utc(time).format('DD/MM/YYYY');
    };

    const contentModal = () => (
        <GolfCardStyle className="modal">
            <div className="box-img">
                <img alt={title} src={urlImage} />
            </div>
            <div>{parse(String(marked(description || "")))}</div>
        </GolfCardStyle>
    );

    const renderContent = () => (
        <>
            <GolfCardStyle className={isEditPage ? 'card edit' : 'card'}>
                <div className="box-hotel">
                    <div className="box-img">
                        <img alt={title} src={urlImage} />
                    </div>
                    <div className="description">
                        <div>
                            <Flex
                                justify="space-between"
                                gap={16}
                                className="itinerary-title-card"
                            >
                                <div>{title}</div>
                                <FontAwesomeIcon
                                    icon={faGolfBall}
                                    className="icon-top"
                                />
                            </Flex>
                            <div className="duration">
                                <strong>
                                    {rounds} Round
                                    {Number(rounds) > 1 ? 's' : ''} at{' '}
                                    {serviceDescription} {documentDescription}{' '}
                                    for {totalPassengers} golfer
                                    {Number(totalPassengers) > 1 ? 's' : ''}
                                </strong>
                            </div>
                            {detailRound?.map((item: any, index: number) => {
                                const { time, numOfPassenger } = item || {};
                                const formattedTime = formatTime(time);
                                const formattedDate = formatDate(time);
                                const golferText =
                                    Number(numOfPassenger) > 1
                                        ? 'golfers'
                                        : 'golfer';

                                return (
                                    <div className="room" key={index}>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faFlag}
                                        />
                                        <div className="time">
                                            {formattedTime} on {formattedDate}
                                        </div>
                                        <div className="round-golf">
                                            x {numOfPassenger} {golferText}
                                        </div>
                                    </div>
                                );
                            })}
                            {buggies.map((item: any, index: number) => {
                                const {
                                    documentDescription,
                                    description,
                                    quantity,
                                    numOfPassenger,
                                } = item || {};
                                const displayDescription =
                                    documentDescription || description;
                                const golferText =
                                    Number(numOfPassenger) > 1
                                        ? 'golfers'
                                        : 'golfer';

                                return (
                                    <div className="room" key={index}>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faGolfBallTee}
                                        />
                                        <div className="time">
                                            {displayDescription}
                                        </div>
                                        <div className="round-golf">
                                            x {quantity} for {numOfPassenger}{' '}
                                            {golferText}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {image && description && (
                            <div
                                className="btnSeeDetail"
                                onClick={() => showModal()}
                            >
                                See details
                            </div>
                        )}
                    </div>
                </div>
                {isEditPage && renderComment()}
            </GolfCardStyle>
            <CommentComponent
                isEdit={isAddComment}
                initialValue={updateComment ?? agentComment?.comment ?? ''}
                onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                data={data}
            />

            {isModalVisible && (
                <ModalComponent
                    width={525}
                    visible={isModalVisible}
                    onClose={handleClose}
                    title={title}
                    content={contentModal()}
                />
            )}
        </>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return renderContent();
};

export default GolfCardComponent;
