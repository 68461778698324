import React from 'react';
import CartWrapStyleStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { formatCurrency } from 'src/const/contain';
import CartItem from '../cartItem';
import moment from 'moment';

interface CartWrapProps {
    isMiniCart?: boolean;
}

const CartWrap: React.FC<CartWrapProps> = ({ isMiniCart = true }) => {
    const {
        price: priceTotal,
        groups,
        tourName,
        isEvent,
    } = useSelector(quoteDetailSelector);

    const resultGroups =
        groups && typeof groups === 'object'
            ? Object?.entries(groups)
                  ?.map(([group, items]) => ({
                      group,
                      items,
                  }))
                  .sort((a, b) => a.group.localeCompare(b.group))
            : [];

    const { totalPrice, totalDeposit, dueDate, deposit2, totalDiscount } =
        priceTotal;

    const { amount, dueDate: deposit2DueDate } = deposit2;

    const isCheckPriceAndDeposit = !!(
        Number(totalPrice) === Number(totalDeposit)
    );

    const formatDate = (date: string) => {
        if (date) {
            return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }
        return;
    };

    const renderCarts = () => (
        <>
            {resultGroups.map((item, index) => (
                <CartItem
                    key={index}
                    item={item}
                    numOfGroups={resultGroups.length}
                    tourName={tourName}
                    isMiniCart={isMiniCart}
                />
            ))}
        </>
    );

    const renderTotal = () => (
        <div className="box-total">
            {Number(totalDiscount) !== 0 && (
                <div className="item total">
                    <div className="name" style={{ color: '#1a91ff' }}>
                        Your discounts have been applied
                    </div>
                </div>
            )}

            <div className="item total">
                <div className="name">Total Price</div>
                <div className="value">£{formatCurrency(totalPrice)}</div>
            </div>
            {!isCheckPriceAndDeposit && (
                <div className="item">
                    <div className="name">Deposit due now</div>
                    <div className="value">£{formatCurrency(totalDeposit)}</div>
                </div>
            )}
            {!isEvent && Number(amount) > 0 && (
                <div className="item">
                    <div className="name">
                        Deposit due {formatDate(deposit2DueDate)}
                    </div>
                    <div className="value">£{formatCurrency(amount)}</div>
                </div>
            )}

            {!isCheckPriceAndDeposit && (
                <div className="item">
                    <div className="name">Balance Due Date</div>
                    <div className="value">{formatDate(dueDate)}</div>
                </div>
            )}
        </div>
    );

    return (
        <CartWrapStyleStyle className={isMiniCart ? 'mini-cart' : ''}>
            {renderCarts()}
            {renderTotal()}
        </CartWrapStyleStyle>
    );
};

export default CartWrap;
