import { AnyAction } from 'redux';
import * as ActionType from '../actions/versionIdState';
export interface VersionIdState {
    versionId: any;
}
const initialState: VersionIdState = {
    versionId: undefined,
};
export const versionIdReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.VERSION_ID_STATE_SUCCESS:
            return {
                ...state,
                versionId: action.payload,
            };
        default:
            return state;
    }
};
