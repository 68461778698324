import styled from '@emotion/styled';

const IntroductionPageStyle = styled('div')`
    line-height: 1.4;
    font-size: 16px;
    .text-signature {
        font-family: 'The Nautigal', cursive;
        font-size: 44.196px;
        font-weight: 400;
        line-height: 44.196px;
    }
    .color-success {
        color: rgb(18, 184, 151);
    }
    .text-line {
        text-decoration-line: underline;
    }

    .intro-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .box-line {
        margin-bottom: 24px;
        border-bottom: 1px solid rgb(220, 220, 220);
        padding-bottom: 48px;
    }
    .box-intro {
        border-radius: 8px;
        border: 1px solid rgb(220, 220, 220);
        background: rgb(255, 255, 255);
        padding: 32px;
        color: rgb(79, 79, 79);
        margin-bottom: 36px;

        .m-bottom {
            margin-bottom: 20px;
        }

        .info-seller {
            display: flex;
            justify-content: space-between;
            align-items: end;
            text-align: right;
            font-weight: 900;
        }
    }

    .box-about {
        .consultant {
            text-align: right;
            font-weight: 900;
        }

        .detail {
            margin-bottom: 16px;
        }
    }

    @media screen and (min-width: 992px) {
        .max-w {
            width: calc(100% - 100px);
        }
    }

    @media screen and (max-width: 767px) {
        .box-intro {
            .info-seller {
                flex-direction: column;
            }
        }
    }
`;
export default IntroductionPageStyle;
