import React, { useCallback, useEffect, useRef, useState } from 'react';
import ViewAndEditQuoteIntroStyle from './style';
import { Popover } from 'antd';
import { updateParamsQuoteDetail } from 'src/redux/actions/paramsQuoteDetailAction';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';

const MAX_LENGTH = 1500;
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['link'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['blockquote', 'code-block'],
    ],
};
const formats = [
    'header',
    'link',
    'script',
    'indent',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'list',
    'blockquote',
    'code-block',
];

interface ViewAndEditQuoteIntroProps {
    initialValue: string;
}

const ViewAndEditQuoteIntro: React.FC<ViewAndEditQuoteIntroProps> = ({
    initialValue,
}) => {
    const dispatch = useDispatch();
    const paramsQuote = useSelector(paramsQuoteDetailSelector);
    const [isShow, setIsShow] = useState<boolean>(false);
    const [content, setContent] = useState<string>(
        paramsQuote?.intro || initialValue,
    );
    const isEditPage = useSelector(toggleEditQuoteSelector);

    const quillRef = useRef<ReactQuill>(null);

    useEffect(() => {
        setIsShow(false);
    }, [isEditPage]);

    const handleShow = () => {
        setIsShow(!isShow);
    };

    const getTextLength = (html: string) => {
        return html.replace(/<[^>]*>/g, '').length;
    };

    const handleChange = useCallback(
        (value: string) => {
            const quillEditor = quillRef.current?.getEditor();
            if (getTextLength(value) <= MAX_LENGTH) {
                setContent(value);
                dispatch(updateParamsQuoteDetail({ intro: value }));
            } else {
                quillEditor?.clipboard.dangerouslyPasteHTML(content || '');
            }
        },
        [content, dispatch],
    );

    const renderViewContent = () => (
        <div
            className="box-value"
            dangerouslySetInnerHTML={{
                __html: paramsQuote?.intro || initialValue,
            }}
        ></div>
    );

    const renderPopover = () => (
        <Popover content={'Click to edit'}>
            <div className="box-popover" onClick={handleShow}>
                {renderViewContent()}
            </div>
        </Popover>
    );

    const renderBoxEdit = () => (
        <div className="box-value">
            <div className="max-length">
                {content.replace(/<[^>]*>/g, '').length}/{MAX_LENGTH}
            </div>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                className="quill"
                value={paramsQuote?.intro || content}
                ref={quillRef}
                onChange={handleChange}
            />
        </div>
    );

    return (
        <ViewAndEditQuoteIntroStyle className="box-quill">
            {isEditPage
                ? isShow
                    ? renderBoxEdit()
                    : renderPopover()
                : renderViewContent()}
        </ViewAndEditQuoteIntroStyle>
    );
};

export default ViewAndEditQuoteIntro;
