import { AnyAction } from 'redux';
import * as ActionType from '../actions/quoteTitleChangeAction';

export interface QuoteTitleState {
    quoteTitle: string;
}
const initialState: QuoteTitleState = {
    quoteTitle: '',
};
export const editQuoteTitleReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.SET_QUOTE_TITLE_ACTION:
            return {
                ...state,
                quoteTitle: action.payload,
            };
        default:
            return state;
    }
};