import React, { useEffect, Fragment } from 'react';
import ItineraryItemStyle from './style';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { Flex } from 'antd';
import HotelCardComponent from 'src/components/hotelCard';
import GolfCardComponent from 'src/components/golfCard';
import FlightCardComponent from 'src/components/flightCard';
import TransferCardComponent from 'src/components/transferCard';
import CarHideCardComponent from 'src/components/carHideCard';
import TourOrEventCardComponent from 'src/components/tourOrEventCard';
import MainImageComponent from 'src/components/mainImageComponent';

interface ItineraryItemProps {
    date: string;
    details: any[];
    order?: string;
}

const ItineraryItem: React.FC<ItineraryItemProps> = ({
    date,
    details = [],
    order,
}) => {
    let isDisplayImage = false;
    useEffect(() => {
        const handleScrollEvent = (event: any) => {
            const id = event.detail.id;
            const section = document.getElementById(id);

            if (section) {
                const elementRect = section.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                window.scrollTo({
                    top: absoluteElementTop - 85,
                    behavior: 'smooth',
                });
            }
        };
        window.addEventListener('scrollToSectionId', handleScrollEvent);
        return () => {
            window.removeEventListener('scrollToSectionId', handleScrollEvent);
        };
    }, []);

    const renderItemItineraryType = (itinerary: any) => {
        const type = itinerary?.type;
        switch (type) {
            case 'Accommodation':
                return <HotelCardComponent data={itinerary} />;
            case 'Golf':
                return <GolfCardComponent data={itinerary} />;
            case 'Flight':
                return <FlightCardComponent data={itinerary} />;
            case 'Transfer':
                return <TransferCardComponent data={itinerary} />;
            case 'CarHire':
                return <CarHideCardComponent data={itinerary} />;
            case 'EventTour':
                return <TourOrEventCardComponent data={itinerary} />;
            case 'Extra':
                return renderExtra(itinerary);
            default:
                return;
        }
    };

    const renderExtra = (itinerary: any) => {
        const extraComments = itinerary?.comments || [];
        return (
            <div className="card extra">
                <ul className="tour-comment">
                    {extraComments.map((item: any, index: any) => {
                        return (
                            <li
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html: item?.text
                                        ?.replace(/\r\n/g, '<br />')
                                        ?.replace(/\n/g, '<br />'),
                                }}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    };

    const renderFreeDay = () => (
        <div className="card ">
            <Flex justify="space-between" className="itinerary-title-card">
                <div>Day at your leisure</div>
                <FontAwesomeIcon className="icon-top" icon={faSun} />
            </Flex>
        </div>
    );

    const renderItemItinerary = () => (
        <ItineraryItemStyle id={`itinerary${order}`}>
            <div className="day-title">
                {order && <span>Day {order}: </span>}
                {moment(date, 'YYYY-MM-D').format('dddd Do MMMM YYYY')}
            </div>
            <div className="detail">
                {details.length > 0
                    ? details.map((item: any, index: any) => (
                          <Fragment key={index}>
                              {order === '1' &&
                                  item?.type === 'EventTour' &&
                                  !isDisplayImage && (
                                      <>
                                          <MainImageComponent
                                              isDisplayOnly={true}
                                          />
                                          {(isDisplayImage = true)}
                                      </>
                                  )}
                              {renderItemItineraryType({
                                  ...item,
                                  itineraryDate: date,
                              })}
                          </Fragment>
                      ))
                    : renderFreeDay()}
            </div>
        </ItineraryItemStyle>
    );

    return renderItemItinerary();
};

export default ItineraryItem;
