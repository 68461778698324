import React, { useState } from 'react';
import HeaderQuoteDetailStyle from './style';
import { Button, Flex, Row, Col, Spin } from 'antd';
import useQueryApi from 'src/hooks/useApiQuery';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import { useSelector, useDispatch } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { toggleEditQuote } from 'src/redux/actions/toggleEditQuoteAction';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import images from 'src/assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import TitleQuoteDetail from 'src/components/titleQuoteDetail';
import { checkChangeApi } from 'src/api/apiCheckChange';
import ModalComponent from 'src/components/modalCustom';
import ModalConfirmVersion from 'src/components/modalConfirmVersion';
import ModalConfirmSaveQuote from 'src/components/modalConfirmSaveQuote';
import { setParamsQuoteDetail } from 'src/redux/actions/paramsQuoteDetailAction';
import { useLocation } from 'react-router-dom';
import { routerQuoteDetailData } from 'src/const/enum';
import useIsMobile from 'src/hooks/useIsMobile';
import MainImageComponent from 'src/components/mainImageComponent';

interface HeaderQuoteDetailProps {
    isPreview: boolean;
}

const HeaderQuoteDetail: React.FC<HeaderQuoteDetailProps> = ({ isPreview }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);
    const [isLoadingSend, setIsLoadingSend] = useState(false);
    const [isVersionChange, setIsVersionChange] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalSaveVisible, setIsModalSaveVisible] = useState(false);
    const [typeModal, setTypeModal] = useState<string>('');
    const dispatch = useDispatch();
    const location = useLocation();

    const { promotionalBanner = '', promotionalBannerTournament = '' } =
        useSelector(contentfulSelector);

    const { isEvent = false } = useSelector(quoteDetailSelector);
    const isEdit = useSelector(toggleEditQuoteSelector);
    const params = useSelector(paramsQuoteDetailSelector);
    const isMobile: boolean = useIsMobile();

    const promotionalTitle = isEvent
        ? promotionalBannerTournament
        : promotionalBanner;

    const { title = '', id = '' } = useSelector(quoteDetailSelector);

    const isCheckNoSave = () => {
        return (
            isEmpty(params) ||
            params?.title === '' ||
            (params?.priceGroups && areFieldsEmpty(params?.priceGroups))
        );
    };

    const areFieldsEmpty = (obj: { [key: string]: any }): boolean => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (value === null || value === undefined || value === '') {
                    return true;
                }
            }
        }
        return false;
    };

    const { refetch } = useQueryApi(['idCheck'], () => checkChangeApi(id), {
        enabled: false,
    });

    const handleToggleEdit = () => {
        dispatch(toggleEditQuote(isEdit));
        dispatch(setParamsQuoteDetail({}));
    };

    const handleRefetch = async (type: string) => {
        try {
            setIsLoading(true);
            const { data: versionChange }: any = await refetch();
            setTypeModal(type);

            setIsVersionChange(versionChange?.changed || false);
            setIsModalVisible(true);
            setIsLoadingGenerate(false);
            setIsLoadingSend(false);
            setIsLoading(false);
        } catch {
            setIsLoadingGenerate(false);
            setIsLoadingSend(false);
            setIsLoading(false);
        }
    };

    const handleSave = () => {
        setIsModalSaveVisible(true);
    };

    const handleCloseModalSave = () => {
        setIsModalSaveVisible(false);
    };

    const handleGenerateLink = () => {
        setIsLoadingGenerate(true);
        handleRefetch('GenLink');
    };

    const handleSendMail = () => {
        setIsLoadingSend(true);
        handleRefetch('SendMail');
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setTypeModal('');
    };

    const getSubTitle = () => {
        return routerQuoteDetailData.find(
            (router) => `/quoteDetail/${router.path}` === location.pathname,
        )?.title;
    };

    const renderBtnBoxEdit = () => (
        <>
            <Button
                type="primary"
                className=""
                onClick={handleToggleEdit}
                ghost
            >
                Reset Changes
            </Button>
            <Button
                type="primary"
                className=""
                onClick={handleSave}
                ghost
                disabled={isCheckNoSave()}
            >
                Save
            </Button>
        </>
    );

    const renderBtnBoxPreview = () => (
        <>
            <Button
                type="primary"
                className=""
                onClick={handleToggleEdit}
                ghost
                disabled={isLoading}
            >
                Edit
            </Button>
            <Spin spinning={isLoadingGenerate}>
                <Button
                    type="primary"
                    className=""
                    onClick={handleGenerateLink}
                    disabled={isLoading && !isLoadingGenerate}
                >
                    Generate link
                </Button>
            </Spin>
            <Spin spinning={isLoadingSend}>
                <Button
                    type="primary"
                    className=""
                    onClick={handleSendMail}
                    disabled={isLoading && !isLoadingSend}
                >
                    Send
                </Button>
            </Spin>
        </>
    );

    const renderPreview = () => (
        <div className="box-preview">
            <div className="container-ydp">
                <Flex
                    align="center"
                    justify={isMobile ? 'end' : 'space-between'}
                    gap={32}
                >
                    <div className="role">
                        You're viewing quote in{' '}
                        <strong>{isEdit ? 'Edit' : 'Preview'} mode</strong>
                    </div>
                    <Flex align="center" gap={16}>
                        {isEdit ? renderBtnBoxEdit() : renderBtnBoxPreview()}
                    </Flex>
                </Flex>
            </div>
        </div>
    );

    const renderHeader = () => (
        <div className="container-ydp box-header">
            <Row gutter={16} align="bottom">
                <Col xs={24} md={7}>
                    <div className="logo">
                        <img alt="logo" src={images.logoYGT} />
                    </div>
                </Col>
                {promotionalTitle && (
                    <Col xs={24} md={17}>
                        <div className="promotion">
                            <FontAwesomeIcon
                                icon={faCoins}
                                className="iconCoins"
                            />
                            {promotionalTitle}
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );

    const renderBoxTitle = () => (
        <div className="title-detail">
            <div className="container-ydp">
                <TitleQuoteDetail isEdit={isEdit} titleQuoteDetail={title} />
                <div className="sub-title">{getSubTitle()}</div>
            </div>
        </div>
    );

    const renderModalCheckVersionChange = () => (
        <ModalComponent
            width={500}
            visible={isModalVisible}
            onClose={handleCloseModal}
            title={
                !isVersionChange && typeModal === 'GenLink'
                    ? 'Share your quote with your fellow golfers'
                    : 'Confirmation'
            }
            content={
                <ModalConfirmVersion
                    onClose={handleCloseModal}
                    typeModal={typeModal}
                    isVersionChange={isVersionChange}
                />
            }
        />
    );

    return (
        <HeaderQuoteDetailStyle className={isPreview ? 'preview' : ''}>
            {isPreview && renderPreview()}
            {renderHeader()}
            {renderBoxTitle()}

            {isMobile &&
                location.pathname !== `/quoteDetail/itineraryInDetail` && (
                    <div className="container-ydp">
                        <MainImageComponent />
                    </div>
                )}
            {renderModalCheckVersionChange()}
            <ModalConfirmSaveQuote
                onClose={handleCloseModalSave}
                isVisible={isModalSaveVisible}
            />
        </HeaderQuoteDetailStyle>
    );
};

export default HeaderQuoteDetail;
