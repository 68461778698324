import styled from '@emotion/styled';

interface MainImageProps {
    mode?: any;
}

const MainImageStyle = styled('div')<MainImageProps>`
    margin-bottom: 24px;
    .main-image {
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        &::before {
            display: block;
            content: '';
            width: 100%;
            padding-top: 50%;
        }
        img {
            right: 0;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            top: 0;
            position: absolute;
            object-fit: cover;
            border-radius: 6px;
        }
    }

    .edit-image {
        .main-image {
            border: 2px solid rgb(18, 184, 151);
            cursor: pointer;
        }
    }

    .ant-skeleton {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 8px;
        margin-top: 24px;
    }
`;

export default MainImageStyle;
