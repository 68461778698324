import { PayloadAction } from '@reduxjs/toolkit';
import * as ActionType from '../actions/itineraryAction';

export interface itineraryState {
    itinerary: any;
}
const initialState: itineraryState = {
    itinerary: {},
};
export const itineraryReducer = (
    state = initialState,
    action: PayloadAction<any>,
) => {
    switch (action.type) {
        case ActionType.SET_ITINERARY:
            return {
                itinerary: action.payload,
            };
        case ActionType.UPDATE_ITINERARY:
            return {
                ...state,
                itinerary: { ...state.itinerary, ...action.payload },
            };
        default:
            return state;
    }
};
