import styled from '@emotion/styled';
import '../../../assets/colors.scss';
const NavbarQuoteDetailStyle = styled('div')`
    .card {
        background-color: rgb(255, 255, 255);
        flex-shrink: 0;
        border-radius: 8px;
        box-shadow: rgba(156, 171, 194, 0.4) 4px 4px 20px 0px;
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .box-consultant {
        justify-content: space-between;
        display: flex;
        margin-bottom: 20px;
        .consultant {
            width: 60%;
            p {
                margin-bottom: 5px;
            }
            .golfer {
                color: rgb(69, 69, 69);
                font-size: 16px;
                font-weight: 700;
                margin-top: 16px;
            }
        }
    }

    .btn-pay {
        background-color: rgb(18, 184, 151) !important;
        width: 100%;
        height: 56px;
        font-weight: 900;
        font-size: 16px;
    }

    .btnShare {
        width: 100%;
        height: 56px;
        color: rgb(18, 184, 151) !important;
        border-color: rgb(18, 184, 151) !important;
        font-weight: 900;
        font-size: 16px;
    }

    .navbar {
        font-size: 16px;
        font-weight: 700;
        li {
            padding: 3px 0;
            a {
                line-height: 24px;
                text-decoration-line: underline;
                color: rgb(26, 145, 255);
                display: flex;
                align-items: center;
                gap: 6px;
                &:hover {
                    transform: scale(1.05);
                }
                &.active {
                    color: #000;
                    text-decoration-line: none;
                    transform: scale(1);
                }
            }
        }
    }

    .mini-basket {
        .reference {
            margin: -1rem -1rem 1rem;
            background: #f2fffa;
            text-align: center;
            padding: 10px 0;
            border-radius: 8px 8px 0 0;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 767px) {
    }
`;
export default NavbarQuoteDetailStyle;
