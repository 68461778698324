
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthSelector } from 'src/redux/selectors/authenSelector';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const location = useLocation();
    const token = localStorage.getItem('access_token');
    const isAuth = useSelector(isAuthSelector);
    const queryParams = new URLSearchParams(location.search);
    const quoteId = queryParams.get('id') || '';
    const isPreview = !!Number(quoteId);
    if (location.pathname !=="/oauth2/callback" && (!token || !isAuth) && isPreview) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
    return <>{children}</>;
};

export default ProtectedRoute;
