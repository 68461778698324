import { useState, useEffect } from 'react';

const useIsMobile = (threshold: number = 768): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(
        window.innerWidth < threshold,
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < threshold);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [threshold]);

    return isMobile;
};

export default useIsMobile;
