import React, { useEffect, useMemo, useState } from 'react';
import ListQuotesComponent from './style';
import { Input, Popover, Table, Select, Button, Space, Flex } from 'antd';
import { listSearch, listSort } from 'src/const/enum';
import ModalComponent from 'src/components/modalCustom';
import VersionModal from './VersionModal';
import moment from 'moment';
import {
    CloseOutlined,
    SearchOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import NotFound from 'src/components/notFoundData';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { ENV } from 'src/const/evn';
import useQueryApi from 'src/hooks/useApiQuery';
import { getListQuote } from 'src/api/apiGetListQuote';

const ViewTableComponent = () => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true,
        showTotal: () => 'Items per page',
    });
    const [searchText, setSearchText] = useState<string>('');
    const [searchField, setSearchField] = useState<string>(listSearch[0].value);
    const [sortField, setSortField] = useState<string>(listSort[0].value);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [idQuote, setIdQuote] = useState<any>('0');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('access_token');

    const { refetch }: any = useQueryApi(
        ['quotes'],
        () => {
            let param = searchText ? `?${searchField}=${searchText}` : '';
            return getListQuote(param);
        },
        {
            enabled: false,
        },
    );

    const debouncedSearch = useMemo(
        () =>
            debounce(async (value: string) => {
                setPagination((prev) => ({
                    ...prev,
                    current: 1,
                }));
                setIsLoading(true);
                const { data = [], status } = await refetch(value);
                if (status === 'success') {
                    handleSort(data);
                } else handleSort([]);
                setIsLoading(false);
            }, 300),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refetch],
    );

    useEffect(() => {
        if (token) {
            debouncedSearch(searchText);
        }
    }, [searchText, debouncedSearch, token]);

    const handleSort = (data: any[], field?: string) => {
        if (field) {
            setSortField(field);
        }

        const fieldData = field || sortField;
        const sortedData = [...data].sort((a, b) => {
            return (
                new Date(b[fieldData]).getTime() -
                new Date(a[fieldData]).getTime()
            );
        });
        setFilteredData(sortedData);
    };

    const handleCopyLink = (event: any, idVersion: any) => {
        event.stopPropagation();
        const versionId = idVersion?.versionId;
        navigator.clipboard
            .writeText(
                `${ENV.PUBLIC_ENV}/quoteDetail/introduction?id=${versionId}`,
            )
            .then(() => {
                toast.success('Copied to clipboard!');
            })
            .catch((err) => {
                toast.error('Failed to copy to clipboard. Please try again.');
            });
    };

    const showModalHistory = (event: any, record: any) => {
        event.stopPropagation();
        setIdQuote(record?.id);
        setIsModalVisible(true);
    };

    const handleRowClick = (item: any) => {
        window.open(`/quoteDetail/introduction?id=${item.id}`, '_blank');
    };

    const handleTableChange = (pagination: any) => {
        setPagination({
            ...pagination,
            pageSize: pagination.pageSize,
            current: pagination.current,
            showTotal: () => 'Items per page',
        });
    };

    const columns = [
        {
            title: 'Quote Ref',
            dataIndex: 'quoteRef',
            key: 'quoteRef',
            className: 'quoteRefColumn',
            width: 100,
        },
        {
            title: 'Quote Title',
            dataIndex: 'quoteTitle',
            key: 'quoteTitle',
            className: 'quoteTitleColumn',
        },
        {
            title: 'Departure Date',
            dataIndex: 'departureDate',
            key: 'departureDate',
            className: 'departureDateColumn',
            render: (text: string) => {
                return <div>{moment.utc(text).format('D MMM YYYY')}</div>;
            },
            width: 130,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            className: 'clientNameColumn',
            width: 120,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            width: 100,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            render: (text: string, record: any) => (
                <Space className="box-action">
                    <Popover
                        content={
                            record?.status === 'Draft' ? '' : 'Copy quote link'
                        }
                    >
                        <Button
                            type="link"
                            disabled={record?.status === 'Draft'}
                            onClick={(e) => handleCopyLink(e, record)}
                        >
                            <FontAwesomeIcon icon={faLink} />
                        </Button>
                    </Popover>
                    <Button
                        type="link"
                        onClick={(e) => showModalHistory(e, record)}
                    >
                        <ClockCircleOutlined />
                    </Button>
                </Space>
            ),
        },
    ];

    const renderSearchAndSort = () => (
        <Flex justify="space-between" align="center" className="box-search">
            <Flex align="center" gap={16}>
                <Space.Compact>
                    <Select
                        value={searchField}
                        options={listSearch}
                        onChange={(value) => {
                            setSearchField(value);
                            setSearchText('');
                        }}
                        className="field-search"
                    />
                    <Input
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        placeholder={
                            searchField === listSearch[0].value
                                ? 'Enter quote number here'
                                : 'Please enter your client name'
                        }
                        prefix={<SearchOutlined />}
                        size={'middle'}
                        suffix={
                            searchText && (
                                <CloseOutlined
                                    onClick={() => setSearchText('')}
                                />
                            )
                        }
                    />
                </Space.Compact>
                {searchField &&
                    filteredData.length > 0 &&
                    filteredData.length < 20 && (
                        <div>{`${filteredData.length} result(s) found.`}</div>
                    )}
            </Flex>
            <Flex align="center" gap={10}>
                <span>Sort by</span>
                <Select
                    value={sortField}
                    options={listSort}
                    onChange={(value) => handleSort(filteredData, value)}
                    size={'middle'}
                />
            </Flex>
        </Flex>
    );

    return (
        <ListQuotesComponent>
            {renderSearchAndSort()}
            {!isLoading && filteredData.length === 0 ? (
                <NotFound
                    content={
                        searchText ? (
                            <div>
                                <div className="allViewMess">
                                    Hmm... Sorry we couldn’t find any matches
                                    for "{searchText}"
                                </div>
                                <div
                                    className="text-clear"
                                    onClick={() => setSearchText('')}
                                >
                                    Clear search & Show all results
                                </div>
                            </div>
                        ) : (
                            <>Please wait, your results are on the way.</>
                        )
                    }
                />
            ) : (
                <Table
                    dataSource={filteredData}
                    columns={columns}
                    pagination={pagination}
                    rowKey="id"
                    rowClassName={(record, index) =>
                        index % 2 !== 0 ? 'highlight-row' : ''
                    }
                    loading={isLoading}
                    onRow={(record) => {
                        return {
                            onClick: () => handleRowClick(record),
                        };
                    }}
                    onChange={handleTableChange}
                    size="small"
                    scroll={{ y: 'calc(100vh - 360px)' }}
                />
            )}

            {isModalVisible && (
                <ModalComponent
                    width={1201}
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    title={`Quote Reference ${idQuote} - Previous versions`}
                    content={<VersionModal idQuote={idQuote} />}
                />
            )}
        </ListQuotesComponent>
    );
};

export default ViewTableComponent;
