import styled from '@emotion/styled';

const ViewYourMasterComponentStyle = styled('div')`
    
    @media print {
        background-color: #ffffff !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
    .header {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.4px;
    }
    .body {
        padding: 15px;
    }
    .detailAtAGlance {
        margin-bottom: 6px;
        display: flex;
    }
    .titleDetailAtAGlance {
        color: #000;
        font-size: 14px;
        line-height: 24px;
    }
    .centerIconAtAGlance {
        padding-top: 6px;
    }
    .iconDetailAtAGlance {
        color: #12b897;
        margin-right: 10px;
    }
`;

export default ViewYourMasterComponentStyle;
