import React from 'react';
import parse from 'html-react-parser';
import ReadyToBookStyle from './style';
import { formatCurrency } from 'src/const/contain';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import { ENV } from 'src/const/evn';
import { Button } from 'antd';

const ReadyToBookComponent: React.FC = () => {
    const {
        price: priceTotal,
        isEvent,
        id: quoteId,
    } = useSelector(quoteDetailSelector);
    const { readyToBookEvent, readyToBookStandard } =
        useSelector(contentfulSelector);

    const { totalPrice, totalDeposit, dueDate, deposit2 } = priceTotal;
    const { amount, dueDate: deposit2DueDate } = deposit2;

    const replacements: { [key: string]: string } = {
        '{Deposit}': `£${formatCurrency(totalDeposit)}`,
        '{BalanceDueDate}': `${dueDate}`,
        '{QuoteTotalPrice}': `£${formatCurrency(totalPrice)}`,
        '{DepositAmount2}': `£${formatCurrency(amount)}`,
        '{DepositDue2}': `${deposit2DueDate}`,
    };

    const formatData = (readyToBook: any) => {
        if (!readyToBook) {
           return; 
        }
        let data = readyToBook?.content
            .map((item: any) => item.value)
            .join('')
            ?.replace(/\n/g, '<br/>');
        Object.keys(replacements).forEach((key) => {
            const regex = new RegExp(key, 'g');
            data = data?.replace(regex, replacements[key]);
        });

        return data;
    };

    const renderContent = ()=> (
        <div className='content-ready'>
            {isEvent ? (
                <>
                    {parse(
                        Number(totalPrice) === Number(totalDeposit)
                            ? formatData(readyToBookEvent?.content[1])
                            : formatData(readyToBookEvent?.content[0]),
                    )}
                </>
            ):(<>
                {parse(
                        Number(totalPrice) === Number(totalDeposit)
                            ? formatData(readyToBookStandard?.content[0])
                            : Number(totalPrice) !== Number(totalDeposit) &&
                              Number(amount) !== 0
                            ? formatData(readyToBookStandard?.content[2])
                            : formatData(readyToBookStandard?.content[1]),
                    )}
            </>)}
        </div>
    )

    const handleRedirectToPay = () => {
        window.open(`${ENV.URL_PAYMENT}/quotes/QV${quoteId}`, '_blank');
    };

    return (
        <ReadyToBookStyle>
            <div className="title-ready">Ready to book?</div>
            {renderContent()}
            <Button
                type="primary"
                className="btn-pay"
                onClick={handleRedirectToPay}
            >
                Secure Quote Now for £{formatCurrency(totalDeposit)}
            </Button>
        </ReadyToBookStyle>
    );
};

export default ReadyToBookComponent;
