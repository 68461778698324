import React, { useEffect, useState, Fragment } from 'react';
import TransferCardStyle from './style';
import icons from 'src/assets/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTaxi, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CommentComponent from '../comment';
import { Flex } from 'antd';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';

interface TransferCardProps {
    data: any;
}

const TransferCardComponent: React.FC<TransferCardProps> = ({ data }) => {
    const { agentComments = [], carType, pickUp = {}, passenger } = data;

    const pickUpTime = pickUp?.time === '00:00' ? 'time TBC' : pickUp?.time;

    const agentComment = agentComments.length > 0 ? agentComments[0] : {};
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const [isAddComment, setIsAddComment] = useState(false);

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const { itineraryComments = [] }: any = useSelector(
        paramsQuoteDetailSelector,
    );

    const updateComment = itineraryComments.find(
        (comment: any) =>
            comment?.itemId === data?.id &&
            comment?.itineraryDate === data?.itineraryDate,
    )?.comment;

    const renderContent = () => (
        <>
            <TransferCardStyle className={isEditPage ? 'card edit' : 'card'}>
                <Flex
                    justify="space-between"
                    gap={16}
                    className="itinerary-title-card"
                >
                    <div>{carType}</div>
                    <FontAwesomeIcon icon={faTaxi} className="icon-top" />
                </Flex>
                <Flex gap={16} className="item-flight">
                    <FontAwesomeIcon className="icon-detail" icon={faTaxi} />
                    <div>
                        <p className="location">
                            <strong>
                                {`${pickUp?.location} To
                                    ${pickUp?.to} at ${pickUpTime}`}
                            </strong>
                        </p>
                        <p>
                            {passenger} Passenger
                            {Number(passenger) > 1 ? 's' : ''}{' '}
                            <FontAwesomeIcon
                                className="icon-detail"
                                icon={faUserGroup}
                                style={{ marginLeft: '10px' }}
                            />
                        </p>
                    </div>
                </Flex>
                {isEditPage && renderComment()}
            </TransferCardStyle>
            <CommentComponent
                isEdit={isAddComment}
                initialValue={updateComment ?? agentComment?.comment ?? ''}
                onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                data={data}
            />
        </>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return renderContent();
};

export default TransferCardComponent;
