import { Popover, Skeleton } from 'antd';
import React, { useState } from 'react';
import { modifyURL } from 'src/const/contain';
import MainImageStyle from './style';
import { useSelector } from 'react-redux';
import ModalChoseMainImage from '../modalChoseImage';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { contentfulSelector } from 'src/redux/selectors/contentfulSelector';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { itinerarySelector } from 'src/redux/selectors/itinerarySelector';

interface MainImageComponentProps {
    isDisplayOnly?: boolean;
}

const MainImageComponent: React.FC<MainImageComponentProps> = ({
    isDisplayOnly = false,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const isEditPage = useSelector(toggleEditQuoteSelector);
    const {
        mainImage,
        tourName = '',
        isEvent,
    } = useSelector(quoteDetailSelector);
    const {
        defaultImage,
        theMastersImage,
        theOpenImage,
        thePlayersChampsImage,
        theRyderCupImage,
        theUsOpenImage,
        solheimCupImage,
        raceToDubaiImage,
        pgaTourImage,
        dpWorldTourImage,
    } = useSelector(contentfulSelector);

    const { itinerary = [] } = useSelector(itinerarySelector);

    const params = useSelector(paramsQuoteDetailSelector);

    const getFirstGolfImage = (data: any) => {
        for (const dateDetail of data) {
            for (const item of dateDetail?.detail) {
                if (item?.type === 'Golf' && item?.image) {
                    return item.image;
                }
            }
        }
        return null;
    };

    const firstGolfImage = getFirstGolfImage(itinerary);

    const getDefaultImageId = (tour: string) => {
        let imageField: any = defaultImage;
        if (isEvent) {
            switch (true) {
                case tour.includes('Masters'):
                    imageField = theMastersImage;
                    break;
                case tour.includes('Ryder'):
                    imageField = theRyderCupImage;
                    break;
                case tour.includes('The Open'):
                    imageField = theOpenImage;
                    break;
                case tour.includes('US Open'):
                    imageField = theUsOpenImage;
                    break;
                case tour.includes('Solheim'):
                    imageField = solheimCupImage;
                    break;
                case tour.includes('Players'):
                    imageField = thePlayersChampsImage;
                    break;
                case tour.includes('Dubai'):
                    imageField = raceToDubaiImage;
                    break;
                case tour.includes('PGA'):
                    imageField = pgaTourImage;
                    break;
                case tour.includes('DP'):
                    imageField = dpWorldTourImage;
                    break;
                default:
                    imageField = defaultImage;
                    break;
            }
        }else {
            if (firstGolfImage) {
                return '';
            }
        }

        return imageField?.sys?.id || '';
    };

    const { data: defaultImageField } = useQueryApi(
        ['image', getDefaultImageId(tourName)],
        () => getImageApi(getDefaultImageId(tourName)),
        {
            enabled: !mainImage && !!getDefaultImageId(tourName),
        },
    );

    const addHttpsImage = (url: string) => {
        if (url) {
            return `http:${url}`;
        }
        return '';
    };

    const urlImage =
        params?.mainImage ??
        mainImage ??
        (!isEvent
            ? firstGolfImage ??
              addHttpsImage((defaultImageField as any)?.fields?.file?.url)
            : addHttpsImage((defaultImageField as any)?.fields?.file?.url));

    const handleShowModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const renderViewImage = () => (
        <div className="main-image">
            <img alt="" src={modifyURL(urlImage, 1000)} />
        </div>
    );

    const renderPopover = () => (
        <Popover content={'Click to edit'}>
            <div className="edit-image" onClick={handleShowModal}>
                {renderViewImage()}
            </div>
        </Popover>
    );

    return (
        <>
            <MainImageStyle>
                {urlImage ? (
                    <>
                        {isEditPage && !isDisplayOnly
                            ? renderPopover()
                            : renderViewImage()}
                    </>
                ) : (
                    <Skeleton.Image
                        active={true}
                        style={{ width: '100%', height: 413 }}
                    />
                )}
            </MainImageStyle>
            {isModalVisible && (
                <ModalChoseMainImage
                    isVisible={isModalVisible}
                    onClose={handleCloseModal}
                    valueImages={[urlImage]}
                />
            )}
        </>
    );
};

export default MainImageComponent;
