import React, { useEffect } from 'react';
import {
    Route,
    Routes,
    useNavigate,
    useLocation,
    Navigate,
} from 'react-router-dom';
// import ProtectedRoute from './router/protectedRoutes';
import { useDispatch } from 'react-redux';
import DashboardComponent from './pages/Dashboard';
import { authExited, loginAction } from './redux/actions/authenAction';
import { ConfigProvider } from 'antd';
import ProtectedRoute from './router/protectedRoutes';
import LoginPage from './pages/loginPage';
import QuoteDetailPage from './pages/quoteDetail';
import PricingAndBookingPage from './pages/quoteDetail/pricingAndBooking';
import IntroductionPage from './pages/quoteDetail/introduction';
import ImportantPage from './pages/quoteDetail/important';
import TermsAndConditionsPage from './pages/quoteDetail/termsAndConditions';
import ItineraryPage from './pages/quoteDetail/itinerary';

const App: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const domain = window.location.origin;
    const fullURL = `${domain}${location.pathname}${location.search}${location.hash}`;
    const takeDomain = `${domain}`;
    const urlParams = new URLSearchParams(new URL(fullURL).search);
    const codePost = urlParams.get('code');
    if (localStorage.getItem('access_token')) {
        dispatch(authExited(true));
    }

    const loginApiCall = () => {
        const data = {
            code: codePost,
            redirect_uri: `${takeDomain}/oauth2/callback`,
            navigate,
        };
        dispatch(loginAction(data));
    };
    useEffect(() => {
        if (codePost) {
            loginApiCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codePost]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#006699',
                    colorLink: '#1D468D',
                    borderRadius: 6,
                    fontFamily: 'Lato',
                    fontSize: 16,
                },
                components: {
                    Carousel: {
                        arrowSize: 24,
                    },
                },
            }}
        >
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                    path="*"
                    element={
                        <ProtectedRoute>
                            <Routes>
                                <Route
                                    path="/dashboard"
                                    element={<DashboardComponent />}
                                />
                                <Route
                                    path="/quoteDetail"
                                    element={<QuoteDetailPage />}
                                >
                                    <Route
                                        index
                                        element={
                                            <Navigate
                                                to="introduction"
                                                replace
                                            />
                                        }
                                    />
                                    <Route
                                        path="introduction"
                                        element={<IntroductionPage />}
                                    />
                                    <Route
                                        path="itineraryInDetail"
                                        element={<ItineraryPage />}
                                    />
                                    <Route
                                        path="pricingAndBooking"
                                        element={<PricingAndBookingPage />}
                                    />
                                    <Route
                                        path="importantInformation"
                                        element={<ImportantPage />}
                                    />
                                    <Route
                                        path="termsAndConditions"
                                        element={<TermsAndConditionsPage />}
                                    />
                                </Route>
                                <Route
                                    index
                                    path="*"
                                    element={
                                        <Navigate to="/dashboard" replace />
                                    }
                                />
                            </Routes>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </ConfigProvider>
    );
};

export default App;
