import React, { useEffect, useState, Fragment } from 'react';
import FlightCardStyle from './style';
import icons from 'src/assets/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CommentComponent from '../comment';
import { Flex } from 'antd';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';

interface flightCardProps {
    data: any;
}

const FlightCardComponent: React.FC<flightCardProps> = ({ data }) => {
    const {
        agentComments = [],
        name,
        departure = {},
        arrival = {},
        airline = {},
        passenger,
        baggage,
        class: classAirline,
        extras = [],
    } = data;

    const departureDate = departure?.date || '';
    const arrivalDate = arrival?.date || '';

    const agentComment = agentComments.length > 0 ? agentComments[0] : {};
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const [isAddComment, setIsAddComment] = useState(false);

    const { itineraryComments = [] }: any = useSelector(
        paramsQuoteDetailSelector,
    );

    const updateComment = itineraryComments.find(
        (comment: any) =>
            comment?.itemId === data?.id &&
            comment?.itineraryDate === data?.itineraryDate,
    )?.comment;

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const isArrivalNextDay = () => {
        return (
            new Date(arrivalDate)?.getUTCDate() >
            new Date(departureDate)?.getUTCDate()
        );
    };

    const renderContent = () => (
        <>
            <FlightCardStyle className={isEditPage ? 'card edit' : 'card'}>
                <Flex
                    justify="space-between"
                    gap={16}
                    className="itinerary-title-card"
                >
                    <div>{name}</div>
                    <FontAwesomeIcon icon={faPlane} className="icon-top" />
                </Flex>
                <Flex gap={16} className="item-flight">
                    <FontAwesomeIcon
                        className="icon-detail"
                        icon={faPlaneDeparture}
                    />
                    <div>
                        <p className="location">
                            <strong>
                                {departure?.name} ({departure?.code})
                            </strong>
                        </p>
                        <p>
                            Departing{' '}
                            {moment.utc(departureDate).format('HH:mm') ===
                            '00:00'
                                ? 'time TBC'
                                : moment.utc(departureDate).format('HH:mm')}
                        </p>
                    </div>
                </Flex>
                <Flex gap={16} className="item-flight">
                    <FontAwesomeIcon
                        className="icon-detail"
                        icon={faPlaneArrival}
                    />
                    <div>
                        <p className="location">
                            <strong>
                                {arrival?.name} ({arrival?.code}){' '}
                            </strong>
                        </p>
                        <p>
                            Arriving{' '}
                            {moment.utc(arrivalDate).format('HH:mm') === '00:00'
                                ? 'time TBC'
                                : moment.utc(arrivalDate).format('HH:mm')}{' '}
                            {isArrivalNextDay() && '(Next day)'}
                        </p>
                    </div>
                </Flex>
                <div className="flight-info">
                    <div className="text-font">
                        Airline: {airline?.number} {airline?.operatedBy}
                    </div>
                    {classAirline && (
                        <div className="text-font">Class: {classAirline}</div>
                    )}
                    <div className="text-font">
                        Passenger{Number(passenger) > 1 ? 's' : ''}: {passenger}
                    </div>
                    <div className="text-font">
                        <div>Small under seat cabin bag </div>
                        {baggage && baggage !== '0' && baggage !== '0K' ? (
                            <div>{`Baggage Allowance: ${baggage}`}</div>
                        ) : (
                            ''
                        )}
                        {extras?.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    {item?.description} x {item?.quantity}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {isEditPage && renderComment()}
            </FlightCardStyle>
            <CommentComponent
                isEdit={isAddComment}
                initialValue={updateComment ?? agentComment?.comment ?? ''}
                onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                data={data}
            />
        </>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return renderContent();
};

export default FlightCardComponent;
