import styled from '@emotion/styled';

const QuoteDetailPageStyle = styled('div')`
    background: #fff;
    color: #454545;
    padding-bottom: 120px;
    .detail-content {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 150px;
    }
`;
export default QuoteDetailPageStyle;
