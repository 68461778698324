import { PayloadAction } from '@reduxjs/toolkit';
import * as ActionType from '../actions/paramsQuoteDetailAction';

export interface contentfulState {
    params: any;
}
const initialState: contentfulState = {
    params: {},
};
export const paramsQuoteDetailReducer = (
    state = initialState,
    action: PayloadAction<any>,
) => {
    switch (action.type) {
        case ActionType.SET_PARAM_QUOTE_DETAIL:
            return {
                params: action.payload,
            };
        case ActionType.UPDATE_PARAM_QUOTE_DETAIL:
            return {
                ...state,
                params: { ...state.params, ...action.payload },
            };
        default:
            return state;
    }
};
