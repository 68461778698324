import React, { useCallback, useEffect, useState } from 'react';
import ViewAndEditPriceStyle from './style';
import { Input, Popover } from 'antd';
import { updateParamsQuoteDetail } from 'src/redux/actions/paramsQuoteDetailAction';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { formatCurrency } from 'src/const/contain';

interface ViewAndEditPriceProps {
    groupName: string;
    price: string | number;
    isEdit?: boolean;
}

const ViewAndEditPrice: React.FC<ViewAndEditPriceProps> = ({
    groupName,
    price,
    isEdit = true,
}) => {
    const dispatch = useDispatch();
    const [isShowInput, setIsShowInput] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string | number>('');
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const { priceGroups = {} } = useSelector(paramsQuoteDetailSelector);
    useEffect(() => {
        setIsShowInput(false);
        setInputValue(price.toString());
    }, [price, isEditPage]);

    const handleShowInput = () => {
        setIsShowInput(!isShowInput);
    };

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            if (/^\d*\.?\d*$/.test(value)) {
                setInputValue(value);
                let paramPrices = {
                    priceGroups: priceGroups,
                };
                let priceGroup: any = {};
                priceGroup[groupName] = value;
                paramPrices.priceGroups = {
                    ...priceGroups,
                    ...priceGroup,
                };
                dispatch(updateParamsQuoteDetail(paramPrices));
            }
        },
        [dispatch, groupName, priceGroups],
    );

    const formatPrice = (price: string | number) => {
        return `£${formatCurrency(price)}`;
    };

    const renderViewPrice = () => <div>{formatPrice(price)}</div>;

    const renderPopover = () => (
        <Popover content={'Click to edit'}>
            <div className="edit-title" onClick={handleShowInput}>
                {formatPrice(price)}
            </div>
        </Popover>
    );

    const renderInputPrice = () => (
        <div>
            <Input
                className="input"
                variant="borderless"
                defaultValue={price}
                onChange={handleInputChange}
                value={inputValue}
                status={inputValue ? '' : 'error'}
            />
        </div>
    );

    return (
        <ViewAndEditPriceStyle>
            {isEditPage && isEdit
                ? isShowInput
                    ? renderInputPrice()
                    : renderPopover()
                : renderViewPrice()}
        </ViewAndEditPriceStyle>
    );
};

export default ViewAndEditPrice;
