import { AnyAction } from 'redux';
import * as ActionType from '../actions/quoteIdAction';


export interface QuoteIdState {
    quoteId: any;
}
const initialState: QuoteIdState = {
    quoteId: '',
};
export const quoteIdReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.UPDATE_QUOTEID_SUCCESS:
            return {
                ...state,
                quoteId: action.payload,
            };
        default:
            return state;
    }
};
