import styled from '@emotion/styled';

const DashboardStyle = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    background: #f5f5f5;
    position: fixed;
    .menuDashboard {
        width: 18%;
        display: flex;
        padding: 24px 20px 30px 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        /* shadow */
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    }
    .tableDashboard {
        width: 86%;
        padding: 48px;
        .table {
            width: 100%;
            border: 1px solid #DCDCDC;
            background: #fff;
            box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
            border-radius: 8px;
            padding: 24px;
        }
    }
    .LogoIMG {
        width: 150px;
        height: 54px;
    }
    .btnQuotes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #12b897;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        align-items: center;
    }
    .avatar {
        box-sizing: border-box;
        min-width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4f4f4f;
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        border-radius: 20px;
        margin-right: 10px;
    }
    .iconfile {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
    .groupJobTitleAndName {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .btmAdmin {
        width: 100%;
        display: flex;
        color: #4f4f4f;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
    }
    .groupBtnQuote {
        display: flex;
    }
    .nameSeller {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: 400;
    }
    .position {
        color: #4f4f4f;
        font-size: 12px;
        font-weight: 400;
        margin-top: 5px;
    }
    .showNameSeller {
        color: #4f4f4f;
        font-size: 38px;
        font-weight: 700;
    }

    .menuBtn {
        width: 100%;
        height: 50%;
    }
    .ant-btn-default {
        border: none !important;
    }
    .ant-popover-inner-content .logOutBtn {
        cursor: pointer !important;
    }
    .dropdown-content-login {
        position: fixed;
        bottom: 31px;
        left: 200px;
        bottom: 12px;
        display: flex;
        flex-direction: column;
        z-index: 12;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        background-color: #ffffff;
        border: 1px solid #ccc;
        margin-bottom: 10px;
        padding: 10px 16px 10px 16px;
        cursor: pointer !important;
    }

    .headerTable {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
    }

    .version {
        position: absolute;
        bottom: 5px;
        left: 26px;
        font-size: 12px;
        font-style: italic;
    }
    @media screen and (max-width: 768px) {
        .menuDashboard {
            width: 20%;
        }
        .LogoIMG {
            width: 100%;
        }
        .tableDashboard {
            width: 80%;
            padding: 29px 24px 29px 24px;
        }
    }
`;
export default DashboardStyle;
