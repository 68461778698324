import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from 'antd/es/input/Search';
import { toast } from 'react-toastify';
import CopyLinkVersionStyle from './style';
import { ENV } from 'src/const/evn';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';

interface CopyLinkVersionProps {
    onDoneCopy: () => void;
}
const CopyLinkVersion: React.FC<CopyLinkVersionProps> = ({ onDoneCopy }) => {
    const { version } = useSelector(quoteDetailSelector);
    const { id: versionId } = version;
    const linkQuote = `${ENV.PUBLIC_ENV}/quoteDetail/introduction?id=${versionId}`;
    const onCopy = () => {
        navigator.clipboard
            .writeText(linkQuote)
            .then(() => {
                toast.success('Copied to clipboard!');
                onDoneCopy();
            })
            .catch((err) => {
                toast.error('Failed to copy to clipboard. Please try again.');
                onDoneCopy();
            });
    };
    return (
        <CopyLinkVersionStyle>
            <Search
                className="inputCopy"
                placeholder="input search text"
                value={linkQuote}
                readOnly
                enterButton="Copy"
                size="large"
                onSearch={onCopy}
            />
            <div className="contentShare">
                <FontAwesomeIcon icon={faCircleInfo} className="faCircleInfo" />
                Anyone with the link will be able to see this quote
            </div>
        </CopyLinkVersionStyle>
    );
};

export default CopyLinkVersion;
