import React, { useEffect, useState } from 'react';
import FooterQuoteDetailStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import ModalSendMessage from 'src/components/modalSendMessage';
import { PHONE_YGT } from 'src/const/common';
import { formatPhoneNumber } from 'src/const/contain';
import useIsMobile from 'src/hooks/useIsMobile';

interface FooterQuoteDetailProps {
    isPreview: boolean;
}

const FooterQuoteDetail: React.FC<FooterQuoteDetailProps> = ({ isPreview }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const isMobile: boolean = useIsMobile();

    const queryParams = new URLSearchParams(location.search);
    const quoteIdQuery = queryParams.get('id') || '';

    const { resConsultant } = useSelector(quoteDetailSelector);

    const { telephone } = resConsultant;

    const phone = telephone || PHONE_YGT;

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            let absoluteTop = 0;
            const section = document.getElementById('quote-detail');
            if (section && isMobile) {
                const elementRect = section.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const absoluteElementPreview = isPreview ? 63 : 0;
                absoluteTop = absoluteElementTop - absoluteElementPreview - 15;
            }
            window.scrollTo({
                top: absoluteTop,
                behavior: 'smooth',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, isMobile]);

    const isCheckPathName = (path: string) => {
        return location.pathname === `/quoteDetail/${path}`;
    };

    const handleNavigate = () => {
        if (isCheckPathName('itineraryInDetail')) {
            navigate(`/quoteDetail/pricingAndBooking?id=${quoteIdQuery}`);
        } else navigate(`/quoteDetail/itineraryInDetail?id=${quoteIdQuery}`);
    };

    const phoneCall = () => {
        window.location.href = `tel:${phone}`;
    };

    return (
        <FooterQuoteDetailStyle>
            <div className="container-ydp">
                <div className="box-footer">
                    <Button
                        type="primary"
                        className="btnLink"
                        onClick={handleNavigate}
                    >
                        {`See ${
                            isCheckPathName('itineraryInDetail')
                                ? 'your pricing and booking details'
                                : 'your golf trip in detail'
                        }`}
                    </Button>
                    <div className="">
                        <span className="link" onClick={phoneCall}>
                            Call me <FontAwesomeIcon icon={faPhone} />
                        </span>{' '}
                        <span className="hide-mobile">
                            on {formatPhoneNumber(phone)} to book
                        </span>{' '}
                        or{' '}
                        <span
                            className="link"
                            onClick={() => setIsModalVisible(true)}
                        >
                            Send a message <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                    </div>
                </div>
            </div>
            <ModalSendMessage
                onClose={() => setIsModalVisible(false)}
                isVisible={isModalVisible}
            />
        </FooterQuoteDetailStyle>
    );
};

export default FooterQuoteDetail;
