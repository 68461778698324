import styled from '@emotion/styled';
import '../../../assets/colors.scss';
const PricingAndBookingPageStyle = styled('div')`
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(156, 171, 194, 0.4) 4px 4px 20px 0px;
    padding: 2rem;
    margin-bottom: 2rem;

    .card {
        position: relative;
        margin-bottom: 24px;
        .validTitle {
            font-weight: 700;
            color: rgb(26, 145, 255);
            border: 2px solid;
            font-size: 18px;
            line-height: 20px;
            padding: 8px 10px;
            border-radius: 6px;
            display: table;
        }

        .title {
            color: rgb(0, 0, 0);
            font-size: 24px;
            font-weight: 700;
            margin: 24px 0;
        }

        &.edit {
            border: 2px solid rgb(18, 184, 151);
            padding: 16px;
        }

        .iconAddComment {
            position: absolute;
            right: 0;
            top: 50%;
            margin-right: -25px;
            margin-top: -25px;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .golfLogo {
        max-width: 400px;
        padding: 20px;
        display: table;
        margin: auto;
        img {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 1rem;
    }
`;
export default PricingAndBookingPageStyle;
