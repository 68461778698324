import React, { useEffect, useState, Fragment } from 'react';
import HotelCardStyle from './style';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faHotel } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { modifyURL } from 'src/const/contain';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CommentComponent from '../comment';
import { Flex } from 'antd';
import ModalComponent from '../modalCustom';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';
import { marked } from 'marked';
import parse from 'html-react-parser';
interface HotelCardProps {
    data: any;
}

const HotelCardComponent: React.FC<HotelCardProps> = ({ data }) => {
    const {
        image,
        agentComments = [],
        address = {},
        description,
        name,
        startDate,
        duration,
        roomType = [],
    } = data;

    const title = `Stay at ${name}`;
    const { country, county } = address;
    const viewCounty = county ?? country;
    const agentComment = agentComments.length > 0 ? agentComments[0] : {};
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const [isAddComment, setIsAddComment] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const urlImage = image ? modifyURL(image, 600) : images.keyIndor;

    const { itineraryComments = [] }: any = useSelector(
        paramsQuoteDetailSelector,
    );

    const updateComment = itineraryComments.find(
        (comment: any) =>
            comment?.itemId === data?.id &&
            comment?.itineraryDate === data?.itineraryDate,
    )?.comment;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const renderExtraDetails = (extra?: any[]) => {
        if (!extra || extra.length === 0) return null;

        return extra.map((extraItem, index) => (
            <Fragment key={index}>
                {extraItem?.nbrCode ? `, ${extraItem.nbrCode}` : ''}
                {extraItem?.detail?.[0]?.text
                    ? `, ${extraItem.detail[0].text}`
                    : ''}
            </Fragment>
        ));
    };

    const contentModal = () => (
        <HotelCardStyle className="modal">
            <div className="box-img">
                <img alt={title} src={urlImage} />
            </div>
            <div>{parse(String(marked(description || "")))}</div>
        </HotelCardStyle>
    );

    const renderContent = () => (
        <>
            <HotelCardStyle className={isEditPage ? 'card edit' : 'card'}>
                <div className="box-hotel">
                    <div className="box-img">
                        <img alt={title} src={urlImage} />
                    </div>
                    <div className="description">
                        <div>
                            <Flex
                                justify="space-between"
                                gap={16}
                                className="itinerary-title-card"
                            >
                                <div>{title}</div>
                                <FontAwesomeIcon
                                    icon={faHotel}
                                    className="icon-top"
                                />
                            </Flex>
                            {viewCounty !== '0' && (
                                <div className="country">{viewCounty}</div>
                            )}
                            <div className="duration">
                                <strong>
                                    {' '}
                                    {`${duration} Night${
                                        Number(duration) > 1 ? 's' : ''
                                    } on ${moment(startDate)
                                        .utc()
                                        .format('ddd Do MMMM YYYY')}`}
                                </strong>
                            </div>
                            {roomType?.map((item: any, index: any) => {
                                const {
                                    numOfRooms,
                                    nameRoomType,
                                    numOfPassenger,
                                    singleOccupancy,
                                    boardBasis,
                                    extra,
                                } = item || {};

                                const guestText =
                                    numOfPassenger && numOfPassenger > 1
                                        ? 'guests'
                                        : 'guest';
                                const boardBasisText =
                                    boardBasis &&
                                    boardBasis !== extra?.[0]?.nbrCode
                                        ? `, ${boardBasis}`
                                        : '';
                                const extraDetails = renderExtraDetails(extra);
                                const extraQuantity =
                                    extra?.length > 0
                                        ? `x ${extra[0].quantity}`
                                        : '';

                                return (
                                    <div className="room" key={index}>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faBed}
                                        />{' '}
                                        <div>
                                            {numOfRooms} x {nameRoomType} for{' '}
                                            {numOfPassenger} {guestText}{' '}
                                            {singleOccupancy &&
                                                '(Single Occupancy)'}
                                            {boardBasisText}
                                            {extraDetails} {extraQuantity}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {image && description && (
                            <div
                                className="btnSeeDetail"
                                onClick={() => showModal()}
                            >
                                See details
                            </div>
                        )}
                    </div>
                </div>
                {isEditPage && renderComment()}
            </HotelCardStyle>
            <CommentComponent
                isEdit={isAddComment}
                initialValue={updateComment ?? agentComment?.comment ?? ''}
                onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                data={data}
            />

            {isModalVisible && (
                <ModalComponent
                    width={525}
                    visible={isModalVisible}
                    onClose={handleClose}
                    title={title}
                    content={contentModal()}
                />
            )}
        </>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return renderContent();
};

export default HotelCardComponent;
