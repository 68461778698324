import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Spin } from 'antd';
import ModalSendMessageStyle from './style';
import { useSelector } from 'react-redux';
import { quoteDetailSelector } from 'src/redux/selectors/quoteDetailSelector';
import { useMutationApi } from 'src/hooks/useApiMutation';
import ModalComponent from '../modalCustom';
import TextArea from 'antd/es/input/TextArea';
import { useLocation } from 'react-router-dom';
import { addNoteIntoCRM } from 'src/api/apiAddNoteIntoCRM';

interface ModalSendMessageProps {
    onClose: () => void;
    isVisible: boolean;
}

const ModalSendMessage: React.FC<ModalSendMessageProps> = ({
    onClose,
    isVisible,
}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const quoteId = queryParams.get('id') || '';
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string>('');
    const { resConsultant, title } = useSelector(quoteDetailSelector);

    const { name } = resConsultant;

    const { mutate } = useMutationApi<{ id: any | string; param: any }, any>(
        ({ id, param }) => addNoteIntoCRM(id, param),
        {
            onSuccess: (response) => {
                toast.success(`Email sent successfully!`);
                resetData();
            },
            onError: (error) => {
                toast.error(
                    'Unable to deliver your message at the moment, please try again shortly',
                );
                resetData();
            },
        },
    );

    const resetData = () => {
        setIsLoading(false);
        setMessage('');
        onClose();
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setMessage(value);
    };

    const handleSendMessage = () => {
        if (message.trim()) {
            const params = {
                title: title,
                content: message,
            };
            setIsLoading(true);
            mutate({ id: quoteId, param: params });
        }
    };

    const renderContent = () => (
        <ModalSendMessageStyle>
            <TextArea
                value={message}
                className="textArea"
                onChange={handleChange}
                placeholder="Type your message here ..."
                autoSize={{ minRows: 10, maxRows: 20 }}
            />
            <Spin spinning={isLoading}>
                <Button
                    type="primary"
                    className="btnSend"
                    onClick={handleSendMessage}
                    block
                    disabled={!message.trim()}
                >
                    Send Message
                </Button>
            </Spin>
        </ModalSendMessageStyle>
    );

    return (
        <ModalComponent
            width={525}
            visible={isVisible}
            onClose={onClose}
            title={`Send ${name} a Message About Your Quote`}
            content={renderContent()}
        />
    );
};

export default ModalSendMessage;
