import React, { useEffect, useState, Fragment } from 'react';
import CarHideCardStyle from './style';
import icons from 'src/assets/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { toggleEditQuoteSelector } from 'src/redux/selectors/toggleEditQuoteSelector';
import CommentComponent from '../comment';
import { Flex } from 'antd';
import { paramsQuoteDetailSelector } from 'src/redux/selectors/paramsQuoteDetailSelector';

interface CarHideCardProps {
    data: any;
}

const CarHideCardComponent: React.FC<CarHideCardProps> = ({ data }) => {
    const {
        agentComments = [],
        name,
        pickUp = {},
        dropOff = {},
        passenger,
        nights,
        direction,
        carType,
    } = data;

    const pickUpTime =
        pickUp?.time === '00:00' ? 'time TBC' : pickUp?.time || '';
    const dropOffTime =
        dropOff?.time === '00:00' ? 'time TBC' : dropOff?.time || '';

    const agentComment = agentComments.length > 0 ? agentComments[0] : {};
    const isEditPage = useSelector(toggleEditQuoteSelector);
    const [isAddComment, setIsAddComment] = useState(false);

    useEffect(() => {
        setIsAddComment(false);
    }, [isEditPage]);

    const { itineraryComments = [] }: any = useSelector(
        paramsQuoteDetailSelector,
    );

    const updateComment = itineraryComments.find(
        (comment: any) =>
            comment?.itemId === data?.id &&
            comment?.itineraryDate === data?.itineraryDate,
    )?.comment;

    const renderContent = () => (
        <>
            <CarHideCardStyle className={isEditPage ? 'card edit' : 'card'}>
                <Flex
                    justify="space-between"
                    gap={16}
                    className="itinerary-title-card"
                >
                    <div>
                        {direction === 'Drop Off'
                            ? 'Drop Off Your Hire Car'
                            : name}
                    </div>
                    <FontAwesomeIcon icon={faCar} className="icon-top" />
                </Flex>
                <Flex gap={16} className="item-flight">
                    <FontAwesomeIcon className="icon-detail" icon={faCar} />
                    <div>
                        <p className="location">
                            <strong>
                                {`${direction} vehicle ${
                                    direction === 'Drop Off'
                                        ? `to ${dropOff?.location}`
                                        : `from ${pickUp?.location}`
                                } at ${pickUpTime}
                                    ${dropOffTime} ${
                                    direction === 'Drop Off'
                                        ? ''
                                        : `, ${nights} ${
                                              nights > 1 ? 'days' : 'day'
                                          }`
                                }`}
                            </strong>
                        </p>
                    </div>
                </Flex>

                <div className="flight-info">
                    <div className="text-font">Car type: {carType}</div>
                    <div className="text-font">{passenger} pax</div>
                </div>
                {isEditPage && renderComment()}
            </CarHideCardStyle>
            <CommentComponent
                isEdit={isAddComment}
                initialValue={updateComment ?? agentComment?.comment ?? ''}
                onChangeToggleInput={() => setIsAddComment(!isAddComment)}
                data={data}
            />
        </>
    );

    const renderComment = () => (
        <div className="iconAddComment" onClick={() => setIsAddComment(true)}>
            <img alt="" src={icons.addComment} />
        </div>
    );

    return renderContent();
};

export default CarHideCardComponent;
