import { combineReducers } from '@reduxjs/toolkit';
import { authenReducer } from './authenReducer';
import { roleCheckReducer } from './checkRoleReducer';
import { getListQouteReducer } from './getListQuoteReducer';
import { quoteDetailReducer } from './quoteDetailReducer';
import { editQuoteTitleReducer } from './editQuoteTitleReducer';
import { editQuoteIntroReducer } from './editQuoteIntroReducer';
import { editPricePersonReducer } from './editPriceReducer';
import { contenfullReducer } from './contenfullReducer';
import { quoteIdReducer } from './quoteIdReducer';
import { clientNameReducer } from './clientNameReducer';
import { versionIdReducer } from './versionIdReducer';
import { versionQuoteReducer } from './versionQuoteReducer';
import { contentfulReducer } from './contentfulReducer';
import { toggleEditQuoteReducer } from './toggleEditQuoteReducer';
import { paramsQuoteDetailReducer } from './paramsQuoteDetailReducer';
import { itineraryReducer } from './itineraryReducer';

const rootReducer = combineReducers({
    authen: authenReducer,
    roleCheck: roleCheckReducer,
    listQuote: getListQouteReducer,
    quoteDetail: quoteDetailReducer,
    editQuoteTitle: editQuoteTitleReducer,
    editQuoteIntro: editQuoteIntroReducer,
    editPricePerson: editPricePersonReducer,
    contenfull: contenfullReducer,
    quoteId: quoteIdReducer,
    clientName: clientNameReducer,
    versionId: versionIdReducer,
    versionQuote: versionQuoteReducer,
    contentful: contentfulReducer,
    isEdit: toggleEditQuoteReducer,
    params: paramsQuoteDetailReducer,
    itinerary: itineraryReducer,
});

export { rootReducer };
