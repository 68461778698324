import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './global.scss';
import './index.css';
import store from './redux/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBillWave, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// add icon to libary
library.add(faMoneyBillWave, faCoffee);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient();

root.render(
    <>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </>,
);
