import styled from '@emotion/styled';

const CommentComponentStyle = styled('div')`
    background-color: rgb(29, 50, 84);
    padding: 36px;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 24px;
    line-height: 1.5;

    .box-comment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
        text-align: center;
        .comment-value {
            width: calc(100% - 140px);

            .btn-cancel {
                display: table;
                margin-left: auto;
                margin-top: 16px;
                cursor: pointer;
            }
        }
        .box-avatar {
            width: 100px;
        }
        &.edit {
            align-items: start;
        }
    }

    .textArea {
        border: 1px solid rgb(18, 184, 151);
        font-size: inherit;
        border-radius: 0;
        background: #fff;
    }
    .max-length {
        font-size: 12px;
        line-height: 18px;
        padding: 5px 10px;
        border: 1px solid;
        display: table;
        margin-left: auto;
        margin-top: -30px;
    }

    @media screen and (max-width: 767px) {
        padding: 16px;
        .box-comment {
            flex-direction: column;
            align-items: start;
            text-align: left;
            gap: 5px;

            .comment-value {
                width: calc(100% - 28px);
                .max-length {
                    margin-top: -10px;
                }
            }

            .box-avatar {
                width: auto;
                margin-left: auto;
                > * {
                    width: 56px;
                    height: 56px;
                }
            }
        }
    }
`;

export default CommentComponentStyle;
