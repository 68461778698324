import styled from '@emotion/styled';

const ReviewsStyle = styled('div')`
    .title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 900;

        .point {
            border-radius: 4px;
            background: rgb(18, 184, 151);
            padding: 5px 10px;
            color: rgb(255, 255, 255);
            line-height: 22px;
        }
    }

    .description {
        margin: 16px 0;
        .icon {
            font-weight: 900;
            font-size: 18px;
        }
    }

    .see-more {
        color: rgb(26, 145, 255);
        font-weight: 700;
        cursor: pointer;
    }

    .sign {
        font-style: italic;
        font-weight: 700;
    }

    .item-review {
        padding: 0 16px;
    }

    @media screen and (max-width: 767px) {
        .box-why {
            display: flex;
            gap: 16px;
            .why-num {
                margin-bottom: 0px;
            }
        }
    }
`;
export default ReviewsStyle;
