import styled from '@emotion/styled';

const ItineraryItemStyle = styled('div')`
    margin-top: 24px;
    .day-title {
        color: rgb(0, 0, 0);
        font-size: 22px;
        font-weight: 900;
        margin-bottom: 16px;
    }

    .extra {
        background-color: rgb(238, 249, 255);
        .tour-comment {
            list-style: disc;
            padding-left: 5px;
            li + li {
                padding-top:5px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .day-title {
            font-size: 20px;
        }
    }
`;
export default ItineraryItemStyle;
